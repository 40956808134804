import { isEmpty } from 'lodash';
import { IFile } from 'pages/OtherManagement/types';
import postServices from './postServices';

export const updateFiles = async (prev: IFile[], current: IFile[]) => {
  try {
    const currentFiles: Record<string, IFile> = prev.reduce(
      (p, c) => ({
        ...p,
        [c.id]: c
      }),
      {}
    );
    const newFiles: Record<string, IFile> = current.reduce(
      (p, c) => ({
        ...p,
        [c.id]: c
      }),
      {}
    );
    const deleteFiles: IFile[] = Object.keys(currentFiles).reduce((p, key) => {
      if (!newFiles[key]) {
        return [...p, currentFiles[key]];
      }
      return p;
    }, [] as IFile[]);
    const keepFiles: IFile[] = [];
    const addFiles: IFile[] = Object.keys(newFiles).reduce((p, key) => {
      if (!currentFiles[key]) {
        return [...p, newFiles[key]];
      }
      keepFiles.push(newFiles[key]);
      return p;
    }, [] as IFile[]);

    let updatedFiles: IFile[] = [];

    if (!isEmpty(deleteFiles)) {
      await Promise.all(
        deleteFiles.map(file => postServices.deleteFile({ file }))
      );
    }
    if (!isEmpty(addFiles)) {
      const formData = new FormData();
      addFiles.forEach(item => formData.append('files', item.blob));
      const { data } = await postServices.uploadFiles({ formData });
      updatedFiles = data.data.files;
    }
    return [...keepFiles, ...updatedFiles];
  } catch (error) {
    return false;
  }
};
