import { IUserProductState, UserProductType } from '../@type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserProducts, getUserProductBuilder } from './getUserProduct';
// import {
//   deleteUserProduct,
//   deleteUserProductBuilder
// } from './deleteUserProduct';
// import {
//   getUserDetailed,
//   getUserDetailedBuilder
// } from './getUserProductDetailed';

const initialState: IUserProductState = {
  loading: false,
  userProductData: [],
  pagination: {},
  filter: {
    keyword: undefined,
    type: undefined
  },
  activeTabId: 'all'
};

const { actions, reducer } = createSlice({
  name: 'userProduct',
  initialState,
  reducers: {
    setFilterData: (
      draftState,
      action: PayloadAction<{ keyword?: string; type?: UserProductType }>
    ) => {
      if (action.payload?.keyword) {
        draftState.filter.keyword = action.payload.keyword;
      } else {
        draftState.filter.keyword = undefined;
      }
      draftState.filter.type = action.payload.type;
    },
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;

      if (action.payload === 'all') {
        draftState.filter.type = undefined;
      } else if (action.payload === 'medicine') {
        draftState.filter.type = UserProductType.MEDICINE;
      } else if (action.payload === 'food') {
        draftState.filter.type = UserProductType.FOOD;
      } else {
        draftState.filter.type = UserProductType.HANDMADE;
      }
    }
  },
  extraReducers: builder => {
    getUserProductBuilder(builder);
    // deleteUserProductBuilder(builder);
    // getUserDetailedBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getUserProducts
  // deleteUserProduct,
  // getUserDetailed
};

export { combineActions as userProductActions, reducer };
