import { IPaginationData } from 'app/global-types/globals';

export enum SuperdatProductTab {
  ALL = 'ALL',
  MEDICINE = 'MEDICINE',
  FOOD = 'FOOD'
}

export enum IGetSuperdatProduct {}

export enum SuperdatProductType {
  ALL = 'ALL',
  MEDICINE = 'MEDICINE',
  FOOD = 'FOOD'
}

export interface IGetReviewPayload {
  data: ISuperdatProduct[];
  pagination: IPaginationData;
}

export interface ISuperdatProduct {
  id: number;
  category: string;
  name: string;
  companyName: string;
  suitableFor: string | number;
  diseases: any[];
}

export enum ISuperdatForm {
  CREATED_DATE = 'created_date',
  UPDATED_DATE = 'updated_date',
  TYPE = 'type',
  NAME = 'name',
  COMPANY = 'company',
  AGE = 'age',
  VOLUME = 'volumn',
  REMEMBER_RECORD = 'remember_record',
  RELATED_DISEASE = 'related_disease'
}

export interface IGetSuperdatProductPayload {
  data: ISuperdatProduct[];
  pagination: IPaginationData;
}

export interface ISuperdatProductState {
  loading: boolean;
  superdatProductData: ISuperdatProduct[];
  pagination: IPaginationData;
  filter: {
    keyword?: string;
    type?: SuperdatProductType;
  };
  activeTabId: string;
  selectedSuperdatProduct?: ISuperdatProduct;
}

export interface IGetSuperdatProductArgs extends IGetDataArgs {
  content?: string;
  type?: SuperdatProductType;
}
