import { ISuperdatProduct, ISuperdatProductState } from '../@type';
import { EDIT_SUPERDAT_PRODUCT } from '../constants';
import superdatProductServices from '../service';
('../service');
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { superdatProductActions } from '../_redux/index';

export const editSuperdatProduct = createAsyncThunk<
  any,
  { id: number; content: string; replyId: number },
  ThunkAPIConfig
>(EDIT_SUPERDAT_PRODUCT, async (args, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await superdatProductServices.editSuperdatProductList(
      args as any
    );

    dispatch(
      superdatProductActions.getSuperdatProducts({
        page: 1,
        limit: 5
      })
    );
    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editSuperdatProductBuilder = (
  builder: ActionReducerMapBuilder<ISuperdatProductState>
) => {
  const { pending, fulfilled, rejected } = editSuperdatProduct;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
