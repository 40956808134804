import React, { useState } from 'react';

import { Image } from 'react-bootstrap';

import { IReview } from './types';
import ReviewGridActions from './actions';
import { ColumnType } from 'app/components';
import dateTime from 'date-and-time';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const ListImg: React.FC<any> = (data: IReview) => {
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const images = data.files;

  return (
    <div
      onClick={e => {
        e.stopPropagation();
        if (fileIndex === -1) {
          setFileIndex(0);
        }
      }}
    >
      {fileIndex >= 0 && (
        <Lightbox
          open={fileIndex >= 0}
          close={() => setFileIndex(-1)}
          controller={{ closeOnBackdropClick: true }}
          slides={images.map(it => ({ ...it, src: it.url, alt: 'image 1' }))}
          on={{ view: ({ index: currentIndex }) => setFileIndex(currentIndex) }}
          render={{
            buttonPrev:
              images.length <= 1 || fileIndex === 0 ? () => null : undefined,
            buttonNext:
              images.length <= 1 || fileIndex === images.length - 1
                ? () => null
                : undefined
          }}
        />
      )}
      <div
        style={{
          display: 'flex'
        }}
      >
        {images?.length > 0 &&
          images
            .slice(0, 3)
            .map(file => (
              <Image
                key={file.id}
                src={file.url}
                alt=""
                height={88}
                width={88}
                style={{ objectFit: 'cover' }}
                className="br-radius-8 mr-2"
              />
            ))}
        {images?.length - 3 > 0 && (
          <span
            className="fw-400"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            +{data.files.length - 3}
          </span>
        )}
      </div>
    </div>
  );
};

const generateProductCategory = (key: string) => {
  let value;
  switch (key) {
    case 'MEDICINE':
      value = '영양제';
      break;
    case 'FOOD':
      value = '사료';
      break;
    case 'HANDMADE':
      value = '수제';
      break;
    default:
      break;
  }
  return value;
};

export const REVIEW_COLUMNS: ColumnType<IReview>[] = [
  {
    id: 'createdAt',
    Header: '신청일',
    accessor: (data: IReview) => (
      <div className="text-align-center">
        <b className="fs-16">
          {data?.createdAt
            ? dateTime.format(new Date(data?.createdAt || ''), 'YYYY.MM.DD')
            : '-'}
        </b>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 60,
    disableSortBy: true
  },
  {
    id: 'type',
    Header: '구분',
    accessor: (data: IReview) => (
      <div className="text-align-center">
        <span className="fw-400">
          {generateProductCategory(data?.product?.category)}
        </span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'userName',
    Header: '유저 닉네임',
    accessor: (data: IReview) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.user?.username || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'product',
    Header: '제품',
    accessor: (data: IReview) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.product?.name || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'detail',
    Header: '내용',
    accessor: (data: IReview) => (
      <div className="text-align-center">
        <span className="text-content-elipsis">{data.content || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    disableSortBy: true
  },
  {
    id: 'image',
    Header: '이미지',
    accessor: (data: IReview) => <ListImg {...data} />,
    className: 'justify-content-center',
    width: 100,
    disableSortBy: true
  }
  //   {
  //     id: 'action',
  //     Header: '',
  //     accessor: (data: IReview) => <ReviewGridActions {...data} />,
  //     width: 190
  //   }
];

export const GET_REVIEWS = 'review/getReviews';
export const DELETE_REVIEW = 'review/deleteReview';
export const GET_REVIEW_DETAILED = 'review/getReviewDetailed';
