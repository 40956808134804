import { IPostState } from '../types';
import { createSelector } from '@reduxjs/toolkit';

const getPostState = (states: RootState) => states.post;

export const selectPostLoading = createSelector(
  getPostState,
  (data: IPostState) => data.loading
);

export const selectPostData = createSelector(
  getPostState,
  (data: IPostState) => data.postData
);

export const selectPostPagination = createSelector(
  getPostState,
  (data: IPostState) => data.pagination
);

export const selectFilterData = createSelector(
  getPostState,
  (data: IPostState) => data.filter
);

export const selectActiveTabId = createSelector(
  getPostState,
  (data: IPostState) => data.activeTabId
);

export const selectPostDetailed = createSelector(
  getPostState,
  (data: IPostState) => data.selectedPost
);

// expert post
export const selectExpertPostLoading = createSelector(
  getPostState,
  (data: IPostState) => data.loadingExpert
);

export const selectExpertPostData = createSelector(
  getPostState,
  (data: IPostState) => data.postDataExpert
);

export const selectExpertPostPagination = createSelector(
  getPostState,
  (data: IPostState) => data.paginationExpert
);

export const selectExpertFilterData = createSelector(
  getPostState,
  (data: IPostState) => data.filterExpert
);

export const selectExpertPostDetailed = createSelector(
  getPostState,
  (data: IPostState) => data.selectedPostExpert
);

export const selectExpertPageType = createSelector(
  getPostState,
  (data: IPostState) => data.expertPageType
);
