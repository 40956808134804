import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDeleteReportArgs } from '../types';
import otherManagementServices from '../otherManagementServices';
import { batch } from 'react-redux';
import { otherManagementActions } from '.';
import { toastActions } from 'pages/_commons/Toast/_redux';

export const deleteReport = createAsyncThunk<
  unknown,
  IDeleteReportArgs,
  ThunkAPIConfig
>('deleteReport', async (args, { dispatch, rejectWithValue }) => {
  try {
    await otherManagementServices.deleteReport(args);

    return batch(() => {
      dispatch(
        toastActions.addToast({
          show: true,
          message: '성공',
          type: 'success'
        })
      );
      dispatch(otherManagementActions.getReports());
      dispatch(otherManagementActions.setReportAction('list'));
      dispatch(otherManagementActions.setSelectedReport());
    });
  } catch (error) {
    dispatch(
      toastActions.addToast({
        show: true,
        message: '오류',
        type: 'error'
      })
    );
    return rejectWithValue(error);
  }
});
