import React, { useState } from 'react';

import { Image } from 'react-bootstrap';

import { ColumnType } from 'app/components';
import dateTime from 'date-and-time';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { IUserProduct } from './@type';

export const USER_PRODUCT_COLUMNS: ColumnType<IUserProduct>[] = [
  //   {
  //     id: 'No',
  //     Header: '번호',
  //     accessor: (data: IUserProduct) => {
  //       return (
  //         <div className="text-align-center">
  //           <b className="fs-16">{data?.id}</b>
  //         </div>
  //       );
  //     },
  //     className: 'justify-content-center div-middle',
  //     width: 60
  //   },
  {
    id: 'Registerd',
    Header: '등록일',
    accessor: (data: IUserProduct) => (
      <div className="text-align-center">
        <b className="fs-16">
          {data?.createdAt
            ? dateTime.format(new Date(data?.createdAt || ''), 'YYYY/MM/DD')
            : '-'}
        </b>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 60,
    disableSortBy: true
  },
  {
    id: 'User Name',
    Header: '유저 닉네임',
    accessor: (data: IUserProduct) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.user?.username || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'Type',
    Header: '구분',
    accessor: (data: IUserProduct) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.category || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'Name',
    Header: '제품명',
    accessor: (data: IUserProduct) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.name || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 100,
    disableSortBy: true
  },
  {
    id: 'Manufacturer(MEMO)',
    Header: '제조사(메모)',
    accessor: (data: IUserProduct) => (
      <div className="text-align-center">
        <span className="text-content-elipsis">{data.companyName || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    disableSortBy: true
  }
];

export const GET_REVIEWS = 'review/getReviews';
export const DELETE_REVIEW = 'review/deleteReview';
export const GET_REVIEW_DETAILED = 'review/getReviewDetailed';
