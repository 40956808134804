import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../constants';
import { IAuthUser } from '../types';
import { signIn, signInBuilder } from './signIn';

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (draftState, action: PayloadAction<IAuthUser | undefined>) => {
      draftState.authUser = action.payload;
    }
  },
  extraReducers: builder => {
    signInBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  signIn
};

export { combineActions as authActions, reducer };
