import { IReviewState } from '../types';
import { DELETE_REVIEW } from '../constants';
import reviewServices from '../reviewServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const deleteReview = createAsyncThunk<
  any,
  { reviewId: number },
  ThunkAPIConfig
>(DELETE_REVIEW, async (args, { rejectWithValue }) => {
  try {
    const { data } = await reviewServices.deleteReview(args.reviewId);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReviewBuilder = (
  builder: ActionReducerMapBuilder<IReviewState>
) => {
  const { pending, fulfilled, rejected } = deleteReview;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loading = false;
      const reviewId = action.meta.arg.reviewId;
      const filteredData = draftState.reviewData.filter(
        item => item.id !== reviewId
      );
      draftState.reviewData = filteredData;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
