import { IPaginationData } from 'app/global-types/globals';
import { PetType } from 'pages/PostManagement/types';

export enum PetMedicalStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Requested = 'REQUESTED',
  ALL = 'REQUESTED,COMPLETED',
  Pending = 'PENDING',
  Removed = 'REMOVED'
}

export enum MedicalPageType {
  New = 'NEW',
  Save = 'SAVE',
  View = 'VIEW'
}

export enum PetDisease {
  General = 'GENERAL',
  Respiratory = 'RESPIRATORY',
  Tumor = 'TUMOR',
  Heart = 'HEART',
  Hormone = 'HORMONE',
  Liver = 'LIVER',
  Stomach = 'STOMACH',
  Ophthalmology_Dental = 'OPHTHALMOLOGY_DENTAL',
  Ear = 'EAR',
  Nervous = 'NERVOUS',
  Skin = 'SKIN',
  Urinary = 'URINARY',
  Etc = 'ETC'
}

export interface IGetMedicalArgs extends IGetDataArgs {
  status?: PetMedicalStatus;
}

export interface IODisease {
  id: number;
  disease: PetDisease;
  description: string;
  icon: {
    id: number;
    name: string;
    file: {
      id: number;
      url: string;
    };
  };
}

export interface IMedical {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  status: PetMedicalStatus;
  date: string;
  reported: boolean;
  userId: number;
  petId: number;
  files: [
    {
      id: number;
      url: string;
    }
  ];
  pet: {
    id: number;
    name: string;
    species: string;
    type: PetType;
    avatar: {
      id: number;
      url: string;
    };
    diseases: PetDisease;
    oDiseases: IODisease[];
  };
  user: {
    id: number;
    username: string;
    avatar: {
      id: number;
      url: string;
    };
  };
}

export interface IGetMedicalPayload {
  data: IMedical[];
  pagination: IPaginationData;
}

export interface IMedicalState {
  loading: boolean;
  medicalData: IMedical[];
  pagination: IPaginationData;
  filter: {
    status?: PetMedicalStatus;
  };
  activeTabId: string;
  selectedMedical?: IMedical | undefined;
  selectedMedicalReply?: any;
  backList?: boolean;
}
