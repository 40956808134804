import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetReportDetailArgs, IGetReportDetailPayload, IOtherManagementState } from "../types";
import otherManagementServices from "../otherManagementServices";

export const getReportDetail = createAsyncThunk<IGetReportDetailPayload, IGetReportDetailArgs, ThunkAPIConfig>('getReportDetail', async (args, { rejectWithValue }) => {
    try {
        const { data } = await otherManagementServices.getReportDetail(args);

        return {
            data: data.data
        };
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getReportDetailBuilder = (builder: ActionReducerMapBuilder<IOtherManagementState>) => {
    const { pending, fulfilled, rejected } = getReportDetail;
    builder
        .addCase(pending, draftState => {
            draftState.report.loading = true;
        })
        .addCase(fulfilled, (draftState, action) => {
            draftState.report.loading = false;
            draftState.report.detail = action.payload.data;
        })
        .addCase(rejected, draftState => {
            draftState.report.loading = false;
        })
}
