import React, { PropsWithChildren } from 'react';

interface IfProps {
  condition: boolean;
}

const If: React.FC<PropsWithChildren<IfProps>> = ({ condition, children }) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};

export default If;
