import React, { LazyExoticComponent } from 'react';
import { IconType } from 'react-icons';
import {
  FaUserAlt,
  FaRegNewspaper,
  FaTicketAlt,
  FaStar,
  FaChartBar,
  FaThList
} from 'react-icons/fa';

export interface IRoute {
  key: string;
  path: string;
  title: string;
  Component: LazyExoticComponent<React.ComponentType<any>>;
  exact?: boolean;
  routes?: IRoute[];
  Icon?: IconType;
  showInMainNav?: boolean;
  index?: boolean;
}

export const EMPTY_COMPONENT: React.FC = () => <div></div>;

export const MAIN_NAVIGATION: IRoute[] = [
  {
    key: 'account-management',
    path: '/accounts',
    title: '고객 관리',
    Icon: FaUserAlt,
    Component: React.lazy(
      () => import('pages/AccountManagement/AccountManagementMaster')
    ),
    routes: [
      {
        key: 'account-list',
        path: '/accounts',
        title: '',
        Component: React.lazy(() => import('pages/AccountManagement'))
      },
      {
        key: 'detailed-account',
        path: '/accounts/:id',
        title: '',
        Component: React.lazy(
          () => import('pages/AccountManagement/DetailedAccount')
        )
      }
    ]
  },
  {
    key: 'post-management',
    path: '/posts',
    title: '게시물관리',
    Icon: FaRegNewspaper,
    Component: React.lazy(
      () => import('pages/PostManagement/PostManagementMaster')
    ),
    routes: [
      {
        key: 'post-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/PostManagement'))
      },
      {
        key: 'detailed-post',
        path: ':id',
        title: '',
        Component: React.lazy(() => import('pages/PostManagement/DetailedPost'))
      },
      {
        key: 'expert-column',
        path: 'expert-columns',
        exact: true,
        title: '',
        Component: React.lazy(() => import('pages/PostManagement/DetailedPost'))
      },
      {
        key: 'expert-column-detail',
        path: 'expert-columns/:id',
        exact: true,
        title: '',
        Component: React.lazy(() => import('pages/PostManagement/DetailedPost'))
      }
    ]
  },
  {
    key: 'medical-management',
    path: '/medicals',
    title: '진료표 관리',
    Icon: FaTicketAlt,
    Component: React.lazy(
      () => import('pages/MedicalManagement/MedicalManagementMaster')
    ),
    routes: [
      {
        key: 'medical-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/MedicalManagement'))
      },
      {
        key: 'detailed-medical',
        path: ':id',
        title: '',
        Component: React.lazy(
          () => import('pages/MedicalManagement/DetailedMedical')
        )
      }
    ]
  },
  {
    key: 'disease-tag-management',
    path: '/disease-tag-management',
    title: '질병 관리',
    Icon: FaStar,
    Component: React.lazy(
      () => import('pages/DiseasesTagManagement/DiseasesTabManagementMaster')
    ),
    routes: [
      {
        key: 'disease-tag-management-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/DiseasesTagManagement'))
      }
    ]
  },
  {
    key: 'superdat-product',
    path: '/superdat-product',
    title: '슈퍼닷 제품 관리',
    Icon: FaStar,
    Component: React.lazy(
      () => import('pages/SuperdatProduct/SuperdatProductMaster')
    ),
    routes: [
      {
        key: 'superdat-product-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/SuperdatProduct'))
      },
      {
        key: 'superdat-product-detail',
        path: ':id',
        title: '',
        Component: React.lazy(
          () =>
            import(
              'pages/SuperdatProduct/components/detail/SuperdatProductDetail'
            )
        )
      }
    ]
  },
  {
    key: 'user-product',
    path: '/user-product',
    title: '유저 제품 조회 ',
    Icon: FaStar,
    Component: React.lazy(() => import('pages/UserProduct/UserProductMaster')),
    routes: [
      {
        key: 'user-product-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/UserProduct'))
      },
      {
        key: 'user-product-detail',
        path: ':id',
        title: '',
        Component: React.lazy(
          () => import('pages/UserProduct/components/detail/UserProductDetail')
        )
      }
    ]
  },
  {
    key: 'review-management',
    path: '/reviews',
    title: '리뷰 관리',
    Icon: FaStar,
    Component: React.lazy(
      () => import('pages/ReviewManagement/ReviewManagementMaster')
    ),
    routes: [
      {
        key: 'review-list',
        path: '',
        title: '',
        Component: React.lazy(() => import('pages/ReviewManagement'))
      },
      {
        key: 'detailed-review',
        path: ':id',
        title: '',
        Component: React.lazy(
          () => import('pages/ReviewManagement/DetailedReview')
        )
      }
    ]
  },
  {
    key: 'data-list-management',
    path: '/data-list',
    title: '데이터 목록 관리',
    Icon: FaChartBar,
    Component: React.lazy(() => import('pages/DataListManagement'))
  },
  {
    key: 'statistics-management',
    path: '/statistics',
    title: '통계관리',
    Icon: FaChartBar,
    Component: React.lazy(() => import('pages/StatisticsManagement'))
  },
  {
    key: 'other-management',
    path: '/others',
    title: '기타 관리',
    Icon: FaThList,
    Component: React.lazy(() => import('pages/OtherManagement'))
  }
];

export const ADMIN_MAIN_NAVIGATION: IRoute[] = [];

export const AUTH_NAVIGATION: IRoute[] = [
  {
    key: 'signIn',
    path: '/sign-in',
    title: 'Đăng nhập',
    Component: React.lazy(() => import('pages/Auth/SignIn'))
  }
];
