import { IGetReviewArgs } from './types';
import { apiService } from 'app/utils/api.service';

const reviewServices = {
  getReviewList(params: IGetReviewArgs) {
    const apiUrl = window.appConfig.api.review.getReviewList;
    return apiService.get(apiUrl, {
      params
    });
  },
  deleteReview(reviewId: number) {
    const apiUrl = `${window.appConfig.api.review.getReviewList}/${reviewId}`;
    return apiService.delete(apiUrl);
  },
  getReviewDetailed(reviewId: number) {
    const apiUrl = `${window.appConfig.api.review.getReviewList}/${reviewId}`;
    return apiService.get(apiUrl);
  }
};

export default reviewServices;
