import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAction, IAnnouncement, IBanner, IBannerManagementState, ICategory, ICategoryManagementState, IOtherManagementState, IReport } from "../types";
import { getBanners, getBannersBuilder } from "./getBanners";
import { deleteBanner, deleteBannerBuilder } from "./deleteBanner";
import { saveBanner, saveBannerBuilder } from "./saveBanner";
import { getReports, getReportsBuilder } from "./getReports";
import { getReportDetail, getReportDetailBuilder } from "./getReportDetail";
import { deleteReport } from "./deleteReport";
import { approveReport } from "./approveReport";
import { getAnnouncements, getAnnouncementsBuilder } from "./getAnnouncements";
import { getAnnouncementDetail, getAnnouncementDetailBuilder } from "./getAnnouncementDetail";
import { saveAnnouncement, saveAnnouncementBuilder } from "./saveAnnouncement";
import { deleteAnnouncement } from "./deleteAnnouncement";
import { getCategories, getCategoriesBuilder } from "./getCategories";
import { deleteCategory } from "./deleteCategory";
import { saveCategory } from "./saveCategory";

const initialItemState = {
    data: [],
    loading: false,
    pagination: {},
    action: "list" as IAction,
}

const initialState: IOtherManagementState = {
    banner: initialItemState,
    report: initialItemState,
    announcement: initialItemState,
    category: initialItemState,
}

const { actions, reducer } = createSlice({
    name: "otherManagement",
    initialState,
    reducers: {
        setBannerAction: (draftState, action: PayloadAction<IBannerManagementState['action']>) => {
            draftState.banner.action = action.payload;
        },
        setSelectedBanner: (draftState, action: PayloadAction<IBanner | undefined>) => {
            draftState.banner.selectedBanner = action.payload;
        },
        setReportAction: (draftState, action: PayloadAction<IBannerManagementState['action']>) => {
            draftState.report.action = action.payload;
        },
        setSelectedReport: (draftState, action: PayloadAction<IReport | undefined>) => {
            draftState.report.detail = action.payload;
        },
        setAnnouncementAction: (draftState, action: PayloadAction<IBannerManagementState['action']>) => {
            draftState.announcement.action = action.payload;
        },
        setSelectedAnnouncement: (draftState, action: PayloadAction<IAnnouncement | undefined>) => {
            draftState.announcement.detail = action.payload;
        },
        setCategoryAction: (draftState, action: PayloadAction<ICategoryManagementState['action']>) => {
            draftState.category.action = action.payload;
        },
        setSelectedCategory: (draftState, action: PayloadAction<ICategory | undefined>) => {
            draftState.category.detail = action.payload;
        },
    },
    extraReducers: builder => {
        getBannersBuilder(builder);
        deleteBannerBuilder(builder);
        saveBannerBuilder(builder);
        getReportsBuilder(builder);
        getReportDetailBuilder(builder);
        getAnnouncementsBuilder(builder);
        getAnnouncementDetailBuilder(builder);
        saveAnnouncementBuilder(builder);
        getCategoriesBuilder(builder);
    }
});

const combineActions = {
    ...actions,
    getBanners,
    deleteBanner,
    saveBanner,
    getReports,
    getReportDetail,
    deleteReport,
    approveReport,
    getAnnouncements,
    getAnnouncementDetail,
    saveAnnouncement,
    deleteAnnouncement,
    getCategories,
    deleteCategory,
    saveCategory
};

export { combineActions as otherManagementActions, reducer };
