import classNames from 'classnames';
import { isUndefined } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContainer,
  TabContent,
  TabPane
} from 'react-bootstrap';

export interface TabBarProps {
  items?: TabBarItem[];
  activeIndex?: number;
  actionButtons?: ReactElement[];
  onChangeTab?: (id: string) => void;
  className?: string;
  tabClassName?: string;
  activeTabClassName?: string;
  titleTable?: string;
  totalTableItem?: number;
  itemTableUnit?: string;
  distanceTop?: number;
}

export interface TabBarItem {
  id: string;
  label: string;
  component: ReactElement;
}

const TabBar: React.FC<TabBarProps> = ({
  items,
  activeIndex = 0,
  actionButtons,
  className,
  tabClassName,
  activeTabClassName,
  titleTable,
  totalTableItem,
  itemTableUnit,
  distanceTop,
  onChangeTab
}) => {
  const [activeTab, setActiveTab] = useState(items?.[activeIndex]);

  const handleChangeTab = useCallback(
    (tab: TabBarItem) => {
      setActiveTab(tab);
      onChangeTab && onChangeTab(tab.id);
    },
    [onChangeTab]
  );

  useEffect(() => {
    if (!isUndefined(items)) {
      setActiveTab(items[activeIndex]);
    }
  }, [activeIndex, items]);

  return (
    <TabContainer
      defaultActiveKey={`tab-${activeIndex || 0}`}
      activeKey={`tab-${activeIndex}`}
    >
      <div
        style={{
          marginTop: distanceTop ? distanceTop : 100,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          background: '#fff',
          borderTop: '1px solid #E3E5E8',
          borderLeft: '1px solid #E3E5E8',
          borderRight: '1px solid #E3E5E8',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          height: 58
        }}
      >
        {titleTable ? (
          <>
            <div
              style={{
                paddingLeft: '16px',
                color: '#767F8A'
              }}
            >
              {titleTable}: {totalTableItem}
              {itemTableUnit}
            </div>
          </>
        ) : (
          <div></div>
        )}
        <div className={classNames('mt-16 mt-lg-0')}>
          <Nav
            variant="pills"
            className={classNames('tab-bar flex-nowrap', className)}
            style={{
              marginTop: '0px!important'
            }}
          >
            {items?.map((item, index) => {
              return (
                <>
                  <NavItem
                    className={classNames('flex-fill')}
                    key={index}
                    onClick={() => {
                      handleChangeTab(item);
                    }}
                    style={{
                      height: '50px!important'
                    }}
                  >
                    <NavLink
                      className={classNames(
                        'text-center text-lg-left',
                        tabClassName,
                        {
                          [`${activeTabClassName}`]: activeTab?.id === item.id
                        }
                      )}
                      eventKey={`tab-${index}`}
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                  {index !== items.length - 1 && (
                    <div className={`separate-space w-1 bg-gray-01`} />
                  )}
                </>
              );
            })}
          </Nav>
        </div>
        {actionButtons && (
          <div className="ml-auto">
            <div className="ml-auto d-flex flex-wrap justify-content-end action-buttons mt-n4">
              {actionButtons.map((btn, index) => (
                <div key={index} className="mt-8 ml-8">
                  {btn}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <TabContent>
        {items?.map((item, index) => (
          <TabPane key={`tab-pane-${index}`} eventKey={`tab-${index}`}>
            {item.component}
          </TabPane>
        ))}
      </TabContent>
    </TabContainer>
  );
};

export default TabBar;
