import { IPostState } from '../types';
import postServices from '../postServices';
import { GET_EXPERT_POST_DETAILED } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getExpertPostDetailed = createAsyncThunk<
  any,
  { postId: number },
  ThunkAPIConfig
>(GET_EXPERT_POST_DETAILED, async (args, { rejectWithValue }) => {
  try {
    const { data } = await postServices.getExpertPostDetailed(args.postId);
    return {
      data: data.data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExpertPostDetailedBuilder = (
  builder: ActionReducerMapBuilder<IPostState>
) => {
  const { pending, fulfilled, rejected } = getExpertPostDetailed;
  builder
    .addCase(pending, draftState => {
      draftState.loadingExpert = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loadingExpert = false;
      draftState.selectedPostExpert = data;
    })
    .addCase(rejected, draftState => {
      draftState.loadingExpert = false;
    });
};
