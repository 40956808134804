import { IGetSuperdatProduct } from './@type';
import { apiService } from 'app/utils/api.service';

const superdatProductServices = {
  getSuperdatProductList(params: IGetSuperdatProduct) {
    const apiUrl = window.appConfig.api.superdatProduct.getSuperdatProductList;
    return apiService.get(apiUrl, {
      params
    });
  },
  createSuperdatProductList(data: IGetSuperdatProduct) {
    const apiUrl = window.appConfig.api.superdatProduct.createSuperdatProduct;
    return apiService.post(apiUrl, data);
  },
  deleteSuperdatProductList(data: any) {
    const apiUrl = window.appConfig.api.superdatProduct.deleteSuperdatProduct;
    return apiService.delete(apiUrl, { data });
  },
  editSuperdatProductList(body: any) {
    const apiUrl =
      window.appConfig.api.superdatProduct.editSuperdatProduct?.replace(
        '${id}',
        body?.id
      );

    return apiService.put(apiUrl, body);
  },
  getCategoryList(params: any) {
    const apiUrl = window.appConfig.api.superdatProduct.getCategoryList;
    return apiService.get(apiUrl, params);
  }
};

export default superdatProductServices;
