import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { dataListAPI } from '../dataListManagement.service';
import {
  CategoryType,
  ICategory,
  ICategoryReduxState,
  IGetCategoryParam,
  IResponse
} from '../dataListManagement.types';

export const getBillCategoriesThunk = createAsyncThunk<
  {
    type: CategoryType;
    data: IResponse<ICategory[]>;
    shouldKeepChild?: boolean;
  },
  { type: CategoryType; param: IGetCategoryParam; shouldKeepChild?: boolean },
  ThunkAPIConfig
>(
  'dataList/getBillCategory',
  async ({ type, param, shouldKeepChild }, { rejectWithValue }) => {
    try {
      const { data } = await dataListAPI.getCategory(param);
      return { type, data, shouldKeepChild };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getBillCategoriesBuilder = (
  builder: ActionReducerMapBuilder<ICategoryReduxState>
) => {
  const { pending, fulfilled, rejected } = getBillCategoriesThunk;
  builder
    .addCase(pending, (draftState, action) => {
      const { type } = action.meta.arg;
      switch (type) {
        case 'category':
          draftState.categoryLoading = true;
          break;
        case 'subCategory1':
          draftState.subCategory1Loading = true;
          break;
        case 'subCategory2':
          draftState.subCategory2Loading = true;
          break;
        case 'dbInput':
          draftState.dbInputLoading = true;
          break;
        default:
          break;
      }
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, type, shouldKeepChild } = action.payload;
      switch (type) {
        case 'category':
          draftState.categoryLoading = false;
          draftState.category = data.data;
          if (!shouldKeepChild) {
            draftState.subCategory1 = [];
            draftState.subCategory2 = [];
            draftState.dbInput = [];
          }
          break;
        case 'subCategory1':
          draftState.subCategory1Loading = false;
          draftState.subCategory1 = data.data;
          if (!shouldKeepChild) {
            draftState.subCategory2 = [];
            draftState.dbInput = [];
          }
          break;
        case 'subCategory2':
          draftState.subCategory2Loading = false;
          draftState.subCategory2 = data.data;
          if (!shouldKeepChild) {
            draftState.dbInput = [];
          }
          break;
        case 'dbInput':
          draftState.dbInputLoading = false;
          draftState.dbInput = data.data;
          break;
        default:
          break;
      }
    })
    .addCase(rejected, (draftState, action) => {
      draftState.categoryLoading = false;
      draftState.subCategory1Loading = false;
      draftState.subCategory2Loading = false;
      draftState.dbInputLoading = false;

      const { type } = action.meta.arg;
      switch (type) {
        case 'category':
          draftState.categoryLoading = false;
          draftState.category = [];
          draftState.subCategory1 = [];
          draftState.subCategory2 = [];
          draftState.dbInput = [];
          break;
        case 'subCategory1':
          draftState.subCategory1Loading = false;
          draftState.subCategory1 = [];
          draftState.subCategory2 = [];
          draftState.dbInput = [];
          break;
        case 'subCategory2':
          draftState.subCategory2Loading = false;
          draftState.subCategory2 = [];
          draftState.dbInput = [];
          break;
        case 'dbInput':
          draftState.dbInputLoading = false;
          draftState.dbInput = [];
          break;
        default:
          break;
      }
    });
};
