import React from 'react';
import { AccountStatus, IAccount } from './types';
import { ColumnType } from 'app/components';
import AccountGridActions from './actions';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { confirmModalActions } from 'pages/_commons/ConfirmModal/_redux';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from './_redux';
import { selectFilterData } from './_redux/selectors';

const BtnUnBlock = (props: any) => {
  const { accountDetailed } = props;
  const dispatch = useDispatch();
  const filterData = useSelector(selectFilterData);
  const handleActivateAccount = (e: any) => {
    e.stopPropagation();
    dispatch(
      confirmModalActions.onConfirm({
        title: `고객 차단을 해제하시겠어요?`,
        message: '고객의 슈퍼닷 서비스 이용이 가능해집니다.',
        cancelBtnText: '취소',
        confirmBtnText: '확인',
        variant: 'primary',
        onConfirm: () => {
          if (accountDetailed?.id) {
            dispatch(
              accountActions.activateAccount({ accountId: accountDetailed?.id })
            );
            setTimeout(() => {
              dispatch(
                accountActions.getAccounts({
                  ...filterData,
                  page: 1
                })
              );
            }, 1000);
          }
        }
      })
    );
  };
  return (
    <div
      style={{
        width: '87px',
        height: '34px',
        border: '1px solid #E3E5E8',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '34px',
        color: '#FF4F4F',
        margin: 'auto',
        cursor: 'pointer'
      }}
      onClick={e => handleActivateAccount(e)}
    >
      차단 해제
    </div>
  );
};

const STATUS_LABEL = {
  [AccountStatus.Active]: '활동',
  [AccountStatus.Block]: '차단',
  [AccountStatus.Removed]: '-',
  [AccountStatus.CancelMembership]: '탈퇴'
};

export const ACCOUNT_COLUMNS: ColumnType<IAccount>[] = [
  {
    id: 'accountType',
    Header: '가입 구분',
    accessor: (data: IAccount) => (
      <div>
        <b className="fs-16">{data.userType || '-'}</b>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'accountName',
    Header: '고객명',
    accessor: (data: IAccount) => (
      <div>
        <span className="fw-400">{data.name || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'nickname',
    Header: '닉네임',
    accessor: (data: IAccount) => (
      <div>
        <span className="fw-400">{data.username || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'email',
    Header: '이메일',
    accessor: (data: IAccount) => (
      <div>
        <span className="fw-400">{data.email || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'phone-number',
    Header: '전화번호',
    accessor: (data: IAccount) => (
      <div>
        <span className="fw-400">{data.phoneNumber || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true,
    width: 100
  },
  {
    id: 'status',
    Header: '상태',
    accessor: (data: IAccount) => (
      <div
        className="tb-content"
        style={{
          textAlign: 'center'
        }}
      >
        {/* <Badge
          bg={(() => {
            if (data.status === AccountStatus.Active) {
              return 'success';
            }
            if (data.status === AccountStatus.Block) {
              return 'danger';
            }
            return 'secondary';
          })()}
          className="fs-12"
        >
          {data.status}
        </Badge> */}
        {STATUS_LABEL[data.status]}
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    width: 'auto',
    disableSortBy: true
  }
  // {
  //   id: 'action',
  //   Header: '',
  //   accessor: (data: IAccount) => <AccountGridActions {...data} />,
  //   width: 190
  // }
];

export const ACCOUNT_COLUMNS_BLOCKED: ColumnType<IAccount>[] = [
  {
    id: 'accountType',
    Header: '가입 구분',
    accessor: (data: IAccount) => (
      <div className="tb-content">
        <b className="fs-16">{data.userType || '-'}</b>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'accountName',
    Header: '고객명',
    accessor: (data: IAccount) => (
      <div className="tb-content">
        <span className="fw-400">{data.name || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'nickname',
    Header: '닉네임',
    accessor: (data: IAccount) => (
      <div className="tb-content">
        <span className="fw-400">{data.username || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'email',
    Header: '이메일',
    accessor: (data: IAccount) => (
      <div className="tb-content">
        <span className="fw-400">{data.email || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'phone-number',
    Header: '전화번호',
    accessor: (data: IAccount) => (
      <div className="tb-content">
        <span className="fw-400">{data.phoneNumber || '-'}</span>
      </div>
    ),
    width: 100,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'status',
    Header: '상태',
    accessor: (data: IAccount) => (
      <div
        className="tb-content"
        style={{
          textAlign: 'center'
        }}
      >
        {/* <Badge
          bg={(() => {
            if (data.status === AccountStatus.Active) {
              return 'success';
            }
            if (data.status === AccountStatus.Block) {
              return 'danger';
            }
            return 'secondary';
          })()}
          className="fs-12"
        >
          {data.status}
        </Badge> */}
        {data.status === AccountStatus.Block ? (
          <BtnUnBlock accountDetailed={data} />
        ) : (
          STATUS_LABEL[data.status]
        )}
      </div>
    ),
    className: 'justify-content-center',
    cellClassName: 'text-center',
    width: 'auto',
    disableSortBy: true
  }
  // {
  //   id: 'action',
  //   Header: '',
  //   accessor: (data: IAccount) => <AccountGridActions {...data} />,
  //   width: 190
  // }
];

export enum ACCOUNT_STATUS_KEY {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  BLOCK = 'BLOCK'
}

export const GET_ACCOUNTS = 'account/getAccounts';
export const BLOCK_ACCOUNT = 'account/blockAccount';
export const ACTIVATE_ACCOUNT = 'account/activateAccount';
export const GET_ACCOUNT_DETAILED = 'account/getAccountDetailed';
