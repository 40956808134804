import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetBannerArgs,
  IGetBannerPayload,
  IOtherManagementState
} from '../types';
import otherManagementServices from '../otherManagementServices';

export const getBanners = createAsyncThunk<
  IGetBannerPayload,
  IGetBannerArgs | undefined,
  ThunkAPIConfig
>('getBanners', async (args, { rejectWithValue }) => {
  try {
    const { data } = await otherManagementServices.getBanners({
      page: args?.page || 1,
      limit: args?.limit || 5
    });

    const { result, metaData } = data.data;
    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBannersBuilder = (
  builder: ActionReducerMapBuilder<IOtherManagementState>
) => {
  const { pending, fulfilled, rejected } = getBanners;
  builder
    .addCase(pending, draftState => {
      draftState.banner.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.banner.data = action.payload.data;
      draftState.banner.pagination = action.payload.pagination;
      draftState.banner.loading = false;
    })
    .addCase(rejected, draftState => {
      draftState.banner.loading = false;
    });
};
