import { COLORS } from 'app/constants/common';
import classNames from 'classnames';
import { isArray, isEmpty, isUndefined } from 'lodash';
import If from 'pages/_commons/If';
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Image } from 'react-bootstrap';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { FaFileUpload, FaTimes } from 'react-icons/fa';
import Button from '../Button';
import FormLabel from '../FormLabel';

export interface IDropFile extends DropzoneOptions {
  label?: string;
  value?: File[];
  containerClassName?: string;
  enable?: boolean;
  onError?: (files: FileRejection[]) => void;
  onDelete?: () => void;
  renderFiles?: (files: File[]) => React.ReactNode;
}

const DropFile: React.FC<PropsWithChildren<IDropFile>> = ({
  onError,
  onDelete,
  renderFiles,
  children,
  value,
  label,
  containerClassName,
  enable = true,
  ...props
}) => {
  const [selected, setSelected] = useState<File[] | undefined>(value ? (isArray(value) ? value as File[] : [value]) : undefined);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...props,
    onDrop: (files, fileRejections, event) => {
      props.onDrop && props.onDrop(files, fileRejections, event);
      setSelected(files);
    }
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      onError && onError(fileRejections);
    }
  }, [fileRejections, onError]);

  useEffect(() => {
    if (!value) return;
    setSelected(value);
  }, [value])


  return (
    <div className={classNames(containerClassName)}>
      <If condition={!isUndefined(label)}>
        <FormLabel>{label}</FormLabel>
      </If>
      <div className="position-relative">
        <If condition={enable}>
          <div {...getRootProps({ className: 'dropzone br-radius-8' })}>
            <input {...getInputProps()} />
            <If condition={!children}>
              <FaFileUpload size={32} color={COLORS.GRAY_L01} />
              <h5 className="mt-24">
                Kéo và thả file tại đây hoặc bấm vào để chọn file
              </h5>
              <div className="fs-14 color-gray-l01 fst-italic text-center mt-16">
                <If condition={!isUndefined(props.maxSize)}>
                  Kích thước tối đa: {props.maxSize! / 1024000}MB
                </If>
                <br />
                <If condition={!isUndefined(props.accept)}>
                  Loại file:&nbsp;
                  {isArray(props.accept) ? props.accept.join(', ') : props.accept}
                </If>
              </div>
            </If>
            {children}
          </div>
        </If>
        <If condition={!!renderFiles}>
          {renderFiles!(selected!)}
        </If>
      </div>
    </div>
  );
};

export default DropFile;
