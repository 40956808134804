import { combineReducers } from 'redux';
// common reducers
import { reducer as i18next } from 'pages/_commons/I18nextProvider/_redux/reducers';
import { reducer as toast } from 'pages/_commons/Toast/_redux';
import { reducer as confirmModal } from 'pages/_commons/ConfirmModal/_redux';

// component reducers
import { reducer as auth } from 'pages/Auth/_redux/';
import { reducer as post } from 'pages/PostManagement/_redux';
import { reducer as review } from 'pages/ReviewManagement/_redux';
import { reducer as superdatProduct } from 'pages/SuperdatProduct/_redux';
import { reducer as diseasesTag } from 'pages/DiseasesTagManagement/_redux';
import { reducer as userProduct } from 'pages/UserProduct/_redux';
import { reducer as account } from 'pages/AccountManagement/_redux';
import { reducer as medical } from 'pages/MedicalManagement/_redux';
import { reducer as statistic } from 'pages/StatisticsManagement/_redux';
import { reducer as other } from 'pages/OtherManagement/_redux';
import { billReducer, categoryReducer } from 'pages/DataListManagement/_redux';

export const reducerMappingList = {
  i18next,
  toast,
  confirmModal,
  auth,
  account,
  post,
  review,
  medical,
  statistic,
  superdatProduct,
  userProduct,
  diseasesTag,
  billReducer,
  categoryReducer,
  other
};

export const rootReducer = combineReducers(reducerMappingList);
export type AppState = ReturnType<typeof rootReducer>;
