import { IReviewState, IGetReviewArgs, IGetReviewPayload } from '../types';
import { GET_REVIEWS } from '../constants';
import reviewServices from '../reviewServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getReviews = createAsyncThunk<
  IGetReviewPayload,
  IGetReviewArgs,
  ThunkAPIConfig
>(GET_REVIEWS, async (args, { rejectWithValue }) => {
  try {
    const params = {
      ...args,
      page: args?.page || 1,
      limit: args?.limit || 5,
      content: args.content ? args.content : undefined,
      category: args.type ? args.type : undefined
    };

    const { data } = await reviewServices.getReviewList(params);
    const { result, metaData } = data.data;

    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReviewsBuilder = (
  builder: ActionReducerMapBuilder<IReviewState>
) => {
  const { pending, fulfilled, rejected } = getReviews;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, pagination } = action.payload;
      draftState.loading = false;
      draftState.reviewData = data;
      draftState.pagination = pagination;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
