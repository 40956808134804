import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IOtherManagementState,
  ISaveAnnouncementArgs,
  IUpdateAnnouncementArgs
} from '../types';
import otherManagementServices from '../otherManagementServices';
import { batch } from 'react-redux';
import { toastActions } from 'pages/_commons/Toast/_redux';
import { otherManagementActions } from '.';
import { updateFiles } from '../helpers';
import { isArray } from 'lodash';

export const saveAnnouncement = createAsyncThunk<
  unknown,
  ISaveAnnouncementArgs | IUpdateAnnouncementArgs,
  ThunkAPIConfig
>('saveAnnouncement', async (args, { dispatch, getState, rejectWithValue }) => {
  try {
    const { detail } = getState().other.announcement;
    dispatch(
      toastActions.addToast({
        show: true,
        message: '파일 업로드 중...',
        type: 'warning'
      })
    );
    const files = await updateFiles(detail?.files || [], args.data.files);
    if (!isArray(files)) throw new Error();
    const params: ISaveAnnouncementArgs | IUpdateAnnouncementArgs = {
      data: {
        ...args.data,
        files
      }
    };
    await ((params.data as any).id
      ? otherManagementServices.updateAnnouncement(
          params as IUpdateAnnouncementArgs
        )
      : otherManagementServices.addAnnouncement(params));

    return batch(() => {
      dispatch(
        toastActions.addToast({
          show: true,
          message: '성공',
          type: 'success'
        })
      );
      dispatch(
        otherManagementActions.getAnnouncements({
          limit: 5
        })
      );
      dispatch(otherManagementActions.setAnnouncementAction('list'));
      dispatch(otherManagementActions.setSelectedAnnouncement());
    });
  } catch (error) {
    dispatch(
      toastActions.addToast({
        show: true,
        message: '오류',
        type: 'error'
      })
    );
    return rejectWithValue(error);
  }
});

export const saveAnnouncementBuilder = (
  builder: ActionReducerMapBuilder<IOtherManagementState>
) => {
  const { pending, fulfilled, rejected } = saveAnnouncement;
  builder
    .addCase(pending, draftState => {
      draftState.announcement.loading = true;
    })
    .addCase(fulfilled, draftState => {
      draftState.announcement.loading = false;
    })
    .addCase(rejected, draftState => {
      draftState.announcement.loading = false;
    });
};
