import AppContext from 'app/components/core/AppContext';
import DataApiContext from 'app/components/core/contexts/DataAPIContext';
import AppContainer from 'AppContainer';
// import DonutChart from 'app/components/DonutChart';
import I18next from 'pages/_commons/I18nextProvider';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { reducerMappingList } from './storeConfigs';
import axios from 'axios';
import { IconContext } from 'react-icons';
import { CookiesProvider } from 'react-cookie';

const App: React.FC = () => {
  const handleGetConfigs = async () => {
    try {
      const configUrls = [`${window.location.origin}/config/main.json`];
      const promiseList = configUrls.map(url => {
        return axios.get(url);
      });

      const configResponse = await Promise.all(promiseList);
      const [mainConfigResponse] = configResponse;
      const mainConfig = mainConfigResponse.data as AppConfiguration;
      window.appConfig = {
        ...mainConfig
      };
    } catch (error) {}
  };

  useEffect(() => {
    handleGetConfigs();
  }, []);

  return (
    <DataApiContext.Provider value={{}}>
      <AppContext customReducers={reducerMappingList}>
        <I18next>
          <IconContext.Provider value={{ size: '1.4rem', className: 'icon' }}>
            <BrowserRouter>
              <CookiesProvider>
                <AppContainer />
              </CookiesProvider>
            </BrowserRouter>
          </IconContext.Provider>
        </I18next>
      </AppContext>
    </DataApiContext.Provider>
  );
};

export default App;
