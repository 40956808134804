import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStatisticState } from "../types";
import { getStatistic, getStatisticBuilder } from './getStatistic';
import { getUsers, getUsersBuilder } from './getUsers';
import { filterTopics, filterTypes } from "../constants";

const initialState: IStatisticState = {
    data: [],
    loading: false,
    filter: {
        topic: filterTopics[0].id,
        type: filterTypes[0].id,
    },
    pagination: {},
    users: {
        loading: false,
        data: [],
        pagination: {},
    },
};

const { actions, reducer } = createSlice({
    name: "statistic",
    initialState,
    reducers: {
        setFilter: (draftState, action: PayloadAction<IStatisticState['filter']>) => {
            draftState.filter = action.payload;
        }
    },
    extraReducers: builder => {
        getStatisticBuilder(builder);
        getUsersBuilder(builder);
    }
});

const combineActions = {
    ...actions,
    getStatistic,
    getUsers
};

export { combineActions as statisticActions, reducer };
