import { IMedicalState } from '../types';
import { EDIT_MEDICAL } from '../constants';
import medicalServices from '../medicalServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { medicalActions } from '.';

export const editMedical = createAsyncThunk<
  any,
  { id: number; content: string; replyId: number },
  ThunkAPIConfig
>(EDIT_MEDICAL, async (args, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await medicalServices.editMedical(
      args.id,
      args.content,
      args.replyId
    );

    dispatch(
      medicalActions.getMedicals({
        page: 1,
        limit: 10
      })
    );
    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editMedicalBuilder = (
  builder: ActionReducerMapBuilder<IMedicalState>
) => {
  const { pending, fulfilled, rejected } = editMedical;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.backList = true;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
