import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IBanner, IOtherManagementState } from '../types';
import otherManagementServices from '../otherManagementServices';
import { otherManagementActions } from '.';
import { batch } from 'react-redux';
import { toastActions } from 'pages/_commons/Toast/_redux';

export const deleteBanner = createAsyncThunk<any, IBanner, ThunkAPIConfig>(
  'deleteBanner',
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const { pagination } = getState().other.banner;
      await otherManagementServices.deleteBanner(args);
      return batch(() => {
        dispatch(
          toastActions.addToast({
            show: true,
            message: '성공',
            type: 'success'
          })
        );
        dispatch(
          otherManagementActions.getBanners({
            page: pagination.currentPage,
            limit: 5
          })
        );
        dispatch(otherManagementActions.setBannerAction('list'));
        dispatch(otherManagementActions.setSelectedBanner());
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBannerBuilder = (
  builder: ActionReducerMapBuilder<IOtherManagementState>
) => {
  const { pending, fulfilled, rejected } = deleteBanner;
  builder
    .addCase(pending, draftState => {
      draftState.banner.loading = true;
    })
    .addCase(fulfilled, draftState => {
      draftState.banner.loading = true;
      draftState.banner.selectedBanner = undefined;
    })
    .addCase(rejected, draftState => {
      draftState.banner.loading = false;
    });
};
