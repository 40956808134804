import { IGetPostArgs } from './types';
import { apiService } from 'app/utils/api.service';
import { IDeleteFileArgs, IUploadFileArgs } from 'pages/OtherManagement/types';

const postServices = {
  getPostList(params: IGetPostArgs) {
    const apiUrl = window.appConfig.api.post.getPostList;
    return apiService.get(apiUrl, {
      params
    });
  },
  deletePost(postId: number) {
    const apiUrl = `${window.appConfig.api.post.getPostList}/${postId}`;
    return apiService.delete(apiUrl);
  },
  getPostDetailed(postId: number) {
    const apiUrl = `${window.appConfig.api.post.getPostList}/${postId}`;
    return apiService.get(apiUrl);
  },

  createExpertPost: (post: any) => {
    const apiUrl = window.appConfig.api.post.getExpertPostList;
    return apiService.post(
      window.appConfig.api.post.getExpertPostList,
      post.data
    );
  },

  updateExpertPost: (post: any) => {
    const apiUrl = `${window.appConfig.api.post.getExpertPostList}/${post.data.id}`;
    return apiService.patch(apiUrl, post.data);
  },

  getExpertPostList(params: IGetPostArgs) {
    const apiUrl = window.appConfig.api.post.getExpertPostList;
    return apiService.get(apiUrl, {
      params
    });
  },
  deleteExpertPost(postId: number) {
    const apiUrl = `${window.appConfig.api.post.getExpertPostList}/${postId}`;
    return apiService.delete(apiUrl);
  },
  getExpertPostDetailed(postId: number) {
    const apiUrl = `${window.appConfig.api.post.getExpertPostList}/${postId}`;
    return apiService.get(apiUrl);
  },

  uploadFiles: (params: IUploadFileArgs) =>
    apiService.post(window.appConfig.api.file.uploadFiles, params.formData),
  deleteFile: (params: IDeleteFileArgs) =>
    apiService.delete(window.appConfig.api.file.deleteFile + params.file.id)
};

export default postServices;
