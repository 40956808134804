import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  CategoryType,
  ICategoryReduxState,
  IDraftCategory
} from '../dataListManagement.types';
import {
  getBillCategoriesBuilder,
  getBillCategoriesThunk
} from './category.thunk';

const addElement = (prevDraftCategories: any, newData: IDraftCategory): any => {
  if (Array.isArray(prevDraftCategories)) {
    if (newData.parentId && typeof newData.parentId === 'string') {
      return prevDraftCategories.map(item => addElement(item, newData));
    }
    return [...prevDraftCategories, newData];
  } else {
    if (prevDraftCategories.id === newData.parentId) {
      return {
        ...prevDraftCategories,
        child: [...(prevDraftCategories.child || []), newData]
      };
    }
    return {
      ...prevDraftCategories,
      ...('child' in prevDraftCategories
        ? { child: addElement(prevDraftCategories.child, newData) }
        : {})
    };
  }
};

const initialState: ICategoryReduxState = {
  categoryLoading: false,
  subCategory1Loading: false,
  subCategory2Loading: false,
  dbInputLoading: false,
  category: [],
  subCategory1: [],
  subCategory2: [],
  dbInput: [],
  deleting: false,
  saving: false
};

const { actions, reducer: categoryReducer } = createSlice({
  name: 'dataListCategory',
  initialState,
  reducers: {
    addDraftData: (
      state,
      action: PayloadAction<{
        value: string;
        parentId?: number;
        type?: CategoryType;
      }>
    ) => {
      const prevDraftCategories = [
        ...(state.draftCategories ||
          [...state.category].map(item => ({ id: item.id, name: item.name })))
      ];
      const id = uuidv4();
      const newItem = action.payload.parentId
        ? {
            id,
            name: action.payload.value,
            parentId: action.payload.parentId,
            type: action.payload.type
          }
        : {
            id,
            name: action.payload.value,
            type: action.payload.type
          };
      const newData = addElement(prevDraftCategories, newItem);

      state.draftCategories = newData;

      state.draftCategoriesName = {
        ...state.draftCategoriesName,
        [(action.payload.parentId || 'main') as string]: [
          ...(state.draftCategoriesName?.[
            (action.payload.parentId || 'main') as string
          ] || []),
          newItem
        ]
      };
    },
    clearCategory: state => {
      state.category = [];
    },
    clearSubCategory1: state => {
      state.subCategory1 = [];
      state.subCategory2 = [];
      state.dbInput = [];
    },
    clearSubCategory2: state => {
      state.subCategory2 = [];
      state.dbInput = [];
    },
    clearInputDb: state => {
      state.dbInput = [];
    },
    clearDraftCategory: state => {
      state.draftCategories = undefined;
      state.draftCategoriesName = undefined;
    }
  },
  extraReducers: builder => {
    getBillCategoriesBuilder(builder);
  }
});

const categoryActions = {
  ...actions,
  getBillCategoriesThunk
};

export { categoryActions, categoryReducer };
