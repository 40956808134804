import React, { ComponentProps, useId } from 'react';

export const CustomCheckBox = ({
  checked,
  ...props
}: ComponentProps<'input'>) => {
  const id = useId();
  return (
    <>
      <input
        checked={checked}
        {...props}
        type="checkbox"
        id={id}
        style={{
          display: 'none'
        }}
      />
      <label
        htmlFor={id}
        style={{
          border: `1px solid ${checked ? '#3D95FC' : '#e3e5e8'}`,
          width: 16,
          height: 16,
          borderRadius: 4
        }}
        className="d-flex align-items-center justify-content-center cursor-pointer user-select-none"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="16"
            height="16"
            rx="2"
            fill={checked ? '#3D95FC' : '#fff'}
          />
          <path
            d="M4 8.00001L6.66686 10.6667L12 5.33333"
            stroke="white"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </label>
    </>
  );
};
