import React from 'react';

import dateTime from 'date-and-time';

import { IMedical } from './types';
import { ColumnType } from 'app/components';
import MedicalGridActions from './actions';

export const MEDICAL_COLUMNS: ColumnType<IMedical>[] = [
  {
    id: 'reportDate',
    Header: '신청일',
    accessor: (data: IMedical) => (
      <div>
        <b className="fs-16">
          {data?.createdAt
            ? dateTime.format(new Date(data?.createdAt || ''), 'YYYY.MM.DD')
            : '-'}
        </b>
      </div>
    ),
    width: 80,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'nickname',
    Header: '유저 닉네임',
    accessor: (data: IMedical) => (
      <div>
        <span className="fw-400">{data?.user?.username || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'petName',
    Header: '펫 이름',
    accessor: (data: IMedical) => (
      <div>
        <span className="fw-400">{data.pet.name || '-'}</span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'petVariety',
    Header: '내용',
    accessor: (data: IMedical) => (
      <div>
        <span className="fw-400">{data.pet.species || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'petDisease',
    Header: '질병',
    accessor: (data: IMedical) => (
      <div>
        <span className="fw-400">
          {data?.pet?.oDiseases?.length
            ? data?.pet?.oDiseases.map(
                (_d, index, arr) =>
                  `${_d.icon?.name || '-'} ${
                    index !== arr.length - 1 ? '/' : ''
                  } `
              )
            : '-'}
        </span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'status',
    Header: '상태',
    accessor: (data: IMedical) => (
      <div>
        <span className="fw-400">
          {data.status === 'COMPLETED'
            ? '답변완료'
            : data.status === 'REQUESTED'
            ? '답변대기'
            : '신청대기'}
        </span>
      </div>
    ),
    width: 140,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  },
  {
    id: 'action',
    Header: '',
    accessor: (data: IMedical) => <MedicalGridActions {...data} />,
    width: 100,
    className: 'justify-content-center div-middle',
    cellClassName: 'text-center',
    disableSortBy: true
  }
];

export const GET_MEDICALS = 'medical/getMedicals';
export const GET_MEDICAL_DETAILED = 'medical/getMedicalDetailed';
export const UPLOAD_FILE = 'medical/uploadFile';
export const REPLY_MEDICAL = 'medical/replyMedical';
export const EDIT_MEDICAL = 'medical/editMedical';
export const DELETE_MEDICAL_REPLY = 'medical/deleteMedicalReply';
export const GET_REPLY = 'medical/getReply';
