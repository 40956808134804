import React, { useCallback } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { IRoute, MAIN_NAVIGATION } from 'routes/constants';
import SidebarMenuItem from './SidebarMenuItem';
import SimpleBar from 'simplebar-react';
import { FaSignOutAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { authActions } from 'pages/Auth/_redux';
import { authStorage } from 'pages/Auth/helpers';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const SidebarMenuRender = useCallback(
    (routeData: IRoute[]) =>
      routeData.map(route => <SidebarMenuItem key={route.key} route={route} />),
    []
  );

  const onSignOut = () => {
    dispatch(authActions.setAuthUser());
    authStorage.clear();
    window.location.href = '/sign-in';
  };

  return (
    <div id="sidebar-menu">
      <SimpleBar>
        <div className="flex-1 h-100" style={{ paddingTop: '72px' }}>
          <div className="sidebar-menu">
            <Nav className="flex-column">
              {SidebarMenuRender(MAIN_NAVIGATION)}
            </Nav>
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
