import { apiService } from "app/utils/api.service";
import { IGetStatisticArgs, IGetUsersArgs } from "./types";

const statisticServices = {
    getNewUserStatistic: (params: IGetStatisticArgs) => apiService.get(window.appConfig.api.statistic.getNewUserStatistic, {
        params,
    }),
    getWithdrawUserStatistic: (params: IGetStatisticArgs) => apiService.get(window.appConfig.api.statistic.getWithdrawUserStatistic, {
        params,
    }),
    getFrequencyUserStatistic: (params: IGetStatisticArgs) => apiService.get(window.appConfig.api.statistic.getFrequencyUserStatistic, {
        params,
    }),
    getUsers: (params: IGetUsersArgs) => apiService.get(window.appConfig.api.user.getUsers, {
        params,
    }),
};

export default statisticServices;
