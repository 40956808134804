import React from 'react';

import { useDispatch } from 'react-redux';
import { medicalActions } from './_redux';

import { useNavigate } from 'react-router-dom';
import { FaPenAlt, FaPlus } from 'react-icons/fa';

import Button from 'app/components/Button';
import { IMedical, PetMedicalStatus } from './types';

const MedicalGridActions: React.FC<IMedical> = medical => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = (e: any) => {
    e.stopPropagation();
    dispatch(medicalActions.setSelectedMedicalDetailed(medical));
    navigate('/medicals/' + medical.id + '?editmode=1');
  };

  return (
    <div className="d-flex justify-content-end">
      {medical.status === PetMedicalStatus.Requested && (
        <Button
          variant="icon-primary"
          size="sm"
          style={{
            width: '100px',
            height: '34px',
            background: '#F8F9F9',
            border: '1px solid #E3E5E8',
            borderRadius: '4px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#5E656E'
          }}
          onClick={onClick}
        >
          {/* <FaPlus size={14} /> */}
          <span>레포트 작성</span>
        </Button>
      )}
      {medical.status === PetMedicalStatus.Completed && (
        <Button
          variant="icon-success"
          size="sm"
          style={{
            width: '100px',
            height: '34px',
            background: '#F8F9F9',
            border: '1px solid #E3E5E8',
            borderRadius: '4px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#5E656E'
          }}
          onClick={onClick}
        >
          {/* <FaPenAlt size={14} /> */}
          <span>레포트 수정</span>
        </Button>
      )}
    </div>
  );
};

export default MedicalGridActions;
