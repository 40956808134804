import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetCategoriesArgs,
  IGetCategoriesPayload,
  IOtherManagementState
} from '../types';
import otherManagementServices from '../otherManagementServices';

export const getCategories = createAsyncThunk<
  IGetCategoriesPayload,
  IGetCategoriesArgs | undefined,
  ThunkAPIConfig
>('getCategories', async (args, { rejectWithValue }) => {
  try {
    const { data } = await otherManagementServices.getCategories({
      page: args?.page || 1,
      limit: args?.limit || 5
    });

    return {
      data: data.data.result,
      pagination: data.data.metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCategoriesBuilder = (
  builder: ActionReducerMapBuilder<IOtherManagementState>
) => {
  const { pending, fulfilled, rejected } = getCategories;
  builder
    .addCase(pending, draftState => {
      draftState.category.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.category.loading = false;
      draftState.category.data = action.payload.data;
      draftState.category.pagination = action.payload.pagination;
    })
    .addCase(rejected, draftState => {
      draftState.category.loading = false;
    });
};
