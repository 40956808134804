import { IGetDiseasesTagManagement } from './@type';
import { apiService } from 'app/utils/api.service';

const diseasesTagManagementServices = {
  getDiseasesTagManagementList(params: any) {
    const apiUrl =
      window.appConfig.api.diseasesTag.getDiseasesTagManagementList;
    return apiService.get(apiUrl, {
      params
    });
  },
  createDiseasesTagManagementList(data: any) {
    const apiUrl =
      window.appConfig.api.diseasesTag.createDiseasesTagManagement.replace(
        '${id}',
        data?.id
      );
    return apiService.put(apiUrl, data.body);
  },
  getDiseasesByCategoryId(data: any) {
    const apiUrl =
      window.appConfig.api.diseasesTag.getDiseasesByCategoryId.replace(
        '${id}',
        data?.id
      );
    return apiService.put(apiUrl, data.body);
  }
};

export default diseasesTagManagementServices;
