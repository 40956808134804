import { IMedicalState } from '../types';
import medicalServices from '../medicalServices';
import { GET_MEDICAL_DETAILED } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getMedicalDetailed = createAsyncThunk<
  any,
  { medicalId: number },
  ThunkAPIConfig
>(GET_MEDICAL_DETAILED, async (args, { rejectWithValue }) => {
  try {
    const { data } = await medicalServices.getMedicalDetailed(args.medicalId);
    return {
      data: data.data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMedicalDetailedBuilder = (
  builder: ActionReducerMapBuilder<IMedicalState>
) => {
  const { pending, fulfilled, rejected } = getMedicalDetailed;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.selectedMedical = data;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
