import { IGetUserProduct } from './@type';
import { apiService } from 'app/utils/api.service';

const userProductServices = {
  getUserProductList(params: IGetUserProduct) {
    const apiUrl = window.appConfig.api.userProduct.getUserProductList;
    return apiService.get(apiUrl, {
      params
    });
  }
};

export default userProductServices;
