import { IAccountState } from '../types';
import { GET_ACCOUNT_DETAILED } from '../constants';
import accountServices from '../accountServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getAccountDetailed = createAsyncThunk<
  any,
  { accountId: number },
  ThunkAPIConfig
>(GET_ACCOUNT_DETAILED, async (args, { rejectWithValue }) => {
  try {
    const { data } = await accountServices.getAccountDetailed(args.accountId);
    return {
      data: data.data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAccountDetailedBuilder = (
  builder: ActionReducerMapBuilder<IAccountState>
) => {
  const { pending, fulfilled, rejected } = getAccountDetailed;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.selectedAccount = data;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
