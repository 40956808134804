import { IPostState } from '../types';
import postServices from '../postServices';
import { GET_POST_DETAILED } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getPostDetailed = createAsyncThunk<
  any,
  { postId: number },
  ThunkAPIConfig
>(GET_POST_DETAILED, async (args, { rejectWithValue }) => {
  try {
    const { data } = await postServices.getPostDetailed(args.postId);
    return {
      data: data.data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPostDetailedBuilder = (
  builder: ActionReducerMapBuilder<IPostState>
) => {
  const { pending, fulfilled, rejected } = getPostDetailed;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.selectedPost = data;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
