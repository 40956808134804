import React, {
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, SortingRule } from 'react-table';
import { ColumnType, GridProps } from './types';
import classnames from 'classnames';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { COLORS } from 'app/constants/common';
import SimpleBar from 'simplebar-react';
import { CustomCheckBox } from '../CustomCheckBox';

const Grid: React.FC<GridProps> = React.forwardRef((props, ref) => {
  const uniqueId = useId();
  const checkboxRef = useRef<any>(null);
  const {
    columns,
    data,
    className,
    showIndex,
    cellProps,
    cellHeaderProps,
    cellBodyProps,
    hiddenColumns = [],
    initialSortBy = [],
    indexOffset = 1,
    onSelectedRow,
    selectedRow,
    onRowClick,
    isSelection,
    isSelectionAll
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    headerGroups,
    prepareRow,
    setSortBy
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns, sortBy: initialSortBy }
    },
    useSortBy
  );

  useImperativeHandle(ref, () => ({
    refreshSortBy(sortBy: SortingRule<string>[]) {
      initialSortBy && setSortBy(sortBy);
    }
  }));

  return (
    <SimpleBar className="bg-transparent">
      <Table
        className={classnames(className)}
        {...getTableProps()}
        style={{
          background: 'white',
          margin: 0,
          borderLeft: '1px solid #E3E5E8',
          borderRight: '1px solid #E3E5E8'
        }}
      >
        <thead
          style={{
            background: '#F8F9F9',
            border: '1px solid #E3E5E8',
            color: '#9198A1'
          }}
        >
          {headerGroups.map((headerGroup, index) => {
            const { key: rowKey } = headerGroup.getHeaderGroupProps();
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={rowKey}>
                {showIndex && (
                  <th
                    className={classnames(
                      'p-16 th-middle grid-index-column text-center'
                    )}
                  >
                    번호
                  </th>
                )}
                {headerGroup.headers.map((column, columnIdx) => {
                  const {
                    getHeaderProps,
                    getSortByToggleProps,
                    render,
                    isSorted,
                    isSortedDesc,
                    width
                  } = column;
                  const { key: columnKey } = getHeaderProps();
                  return (
                    <th
                      className={classnames('p-16 th-middle', {
                        'sticky-right bg-gray-247': (column as any).isFixedRight
                      })}
                      {...getHeaderProps(getSortByToggleProps())}
                      key={columnKey}
                      {...cellProps}
                      {...cellHeaderProps}
                    >
                      <div
                        className={classnames(
                          'd-flex',
                          (column as any).className
                        )}
                        style={{ width: width }}
                      >
                        {isSelectionAll && columnIdx === 0 ? (
                          <CustomCheckBox
                            id={uniqueId}
                            ref={checkboxRef}
                            className={`table-col__checkbox ${
                              selectedRow?.length !== data.length
                                ? 'table-col__checkbox--uncheck'
                                : 'table-col__checkbox--check'
                            }`}
                            checked={
                              !!(
                                data.length &&
                                selectedRow?.length === data.length
                              )
                            }
                            disabled={!data.length}
                            type="checkbox"
                            onChange={e => {
                              e.stopPropagation();
                              onSelectedRow(
                                selectedRow?.length !== data.length
                                  ? [...data]
                                  : []
                              );
                            }}
                          />
                        ) : (
                          render('Header')
                        )}
                        {isSorted ? (
                          <span className="ml-6">
                            {isSortedDesc ? (
                              <FaCaretDown size={14} color={COLORS.DANGER} />
                            ) : (
                              <FaCaretUp size={14} color={COLORS.DANGER} />
                            )}
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody
          {...getTableBodyProps()}
          style={{
            color: '#464C52'
          }}
        >
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            const { key: rowKey } = row?.getRowProps();
            return (
              <tr
                {...row.getRowProps()}
                key={rowKey}
                onClick={() => {
                  if (row && onRowClick) {
                    onRowClick(row.original);
                  }
                }}
              >
                {showIndex && (
                  <td
                    className={classnames('p-16 text-center')}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {rowIndex + 1 + (indexOffset - 1) * row?.cells?.length}
                  </td>
                )}
                {row.cells.map((cell, cellIndex) => {
                  const { cellClassName, isFixedRight } =
                    cell.column as ColumnType;
                  const { key: cellKey } = cell.getCellProps();
                  return (
                    <>
                      <td
                        className={classnames(
                          (cell as any).className,
                          cellClassName,
                          {
                            'sticky-right bg-gray-247': isFixedRight,
                            'p-16': !isFixedRight
                          }
                        )}
                        style={{ verticalAlign: 'middle' }}
                        {...cell.getCellProps()}
                        key={cellKey}
                        {...cellProps}
                        {...cellBodyProps}
                      >
                        <div
                          key={cell.row.id}
                          className={`${
                            cellIndex === 0 && 'table__col--selection'
                          }`}
                        >
                          <>
                            {cellIndex === 0 && isSelection && (
                              <CustomCheckBox
                                id={uniqueId}
                                ref={checkboxRef}
                                className={`table-col__checkbox ${
                                  selectedRow[cellIndex]?.id !==
                                  cell?.row?.original?.id
                                    ? 'table-col__checkbox--uncheck'
                                    : 'table-col__checkbox--check'
                                }`}
                                checked={
                                  selectedRow?.find(
                                    (r: any) => r.id === cell?.row?.original?.id
                                  )?.id
                                }
                                value={cell?.row?.original?.id}
                                type="checkbox"
                                onChange={(e: any) => {
                                  e.stopPropagation();
                                  const cloneRows = [...selectedRow];

                                  if (
                                    cloneRows.some(
                                      item => item.id === row.original.id
                                    )
                                  ) {
                                    const data: any = cloneRows?.filter(
                                      item => item.id !== row.original.id
                                    );
                                    onSelectedRow([...data]);
                                  } else {
                                    onSelectedRow([
                                      ...selectedRow,
                                      row.original
                                    ]);
                                  }
                                }}
                              />
                            )}
                            {cell.render('Cell')}
                          </>
                        </div>
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </SimpleBar>
  );
});

export default Grid;
