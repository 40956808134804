import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetStatisticArgs, IGetStatisticPayload, IStatisticState } from "../types";
import { FILTER_TOPIC } from "../constants";
import statisticServices from "../statisticServices";

export const getStatistic = createAsyncThunk<
    IGetStatisticPayload,
    IGetStatisticArgs & { topic: FILTER_TOPIC; },
    ThunkAPIConfig
>('getStatistic', async (args, { rejectWithValue }) => {
    try {

        const { data } = await (function () {
            if (args.topic === FILTER_TOPIC.NEW_USER) return statisticServices.getNewUserStatistic(args);
            else if (args.topic === FILTER_TOPIC.WITHDRAW) return statisticServices.getWithdrawUserStatistic(args);
            else if (args.topic === FILTER_TOPIC.FRENQUENCY) return statisticServices.getFrequencyUserStatistic(args);
            else throw new Error('Topic not found');
        })();
        return {
            data: data.data.statics,
        };
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getStatisticBuilder = (builder: ActionReducerMapBuilder<IStatisticState>) => {
    const { pending, fulfilled, rejected } = getStatistic;
    builder
        .addCase(pending, (draftState) => {
            draftState.loading = true;
        })
        .addCase(fulfilled, (draftState, action) => {
            draftState.loading = false;
            draftState.data = action.payload.data;
        })
        .addCase(rejected, (draftState) => {
            draftState.loading = false;
        })
}
