import { IGetMedicalArgs } from './types';
import { apiService } from 'app/utils/api.service';

const medicalServices = {
  getMedicalList(params: IGetMedicalArgs) {
    const apiUrl = window.appConfig.api.medical.getMedicalList;
    return apiService.get(apiUrl, {
      params
    });
  },
  deleteReply(medicalId: number, replyId: number) {
    const apiUrl = `${window.appConfig.api.medical.getMedicalList}/${medicalId}/reply/${replyId}`;
    return apiService.delete(apiUrl);
  },
  getMedicalDetailed(medicalId: number) {
    const apiUrl = `${window.appConfig.api.medical.getMedicalList}/${medicalId}`;
    return apiService.get(apiUrl);
  },
  uploadImage(data: FormData) {
    const apiUrl = window.appConfig.api.common.uploadFiles;
    return apiService.post(apiUrl, data);
  },
  replyMedical(medicalId: number, content: string) {
    const apiUrl = `${window.appConfig.api.medical.getMedicalList}/${medicalId}/reply`;
    return apiService.post(apiUrl, { content });
  },
  editMedical(medicalId: number, content: string, replyId: number) {
    const apiUrl = `${window.appConfig.api.medical.getMedicalList}/${medicalId}/reply/${replyId}`;
    return apiService.patch(apiUrl, { content });
  },
  getReply(medicalId: number) {
    const apiUrl = `${window.appConfig.api.medical.getMedicalList}/${medicalId}/reply`;
    return apiService.get(apiUrl);
  }
};

export default medicalServices;
