import {
  IMedicalState,
  IGetMedicalArgs,
  IGetMedicalPayload,
  PetMedicalStatus
} from '../types';
import { GET_MEDICALS } from '../constants';
import medicalServices from '../medicalServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'app/utils/constant';

export const getMedicals = createAsyncThunk<
  IGetMedicalPayload,
  IGetMedicalArgs,
  ThunkAPIConfig
>(GET_MEDICALS, async (args, { rejectWithValue }) => {
  try {
    const params = {
      ...args,
      page: args?.page || 1,
      limit: args?.limit || PAGE_SIZE,
      status: args.status ? args.status : undefined
    };

    const { data } = await medicalServices.getMedicalList(params);
    const { result, metaData } = data.data;

    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMedicalsBuilder = (
  builder: ActionReducerMapBuilder<IMedicalState>
) => {
  const { pending, fulfilled, rejected } = getMedicals;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, pagination } = action.payload;
      draftState.loading = false;
      draftState.medicalData = data;
      draftState.pagination = pagination;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
