import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IGetUsersArgs, IGetUsersPayload, IStatisticState } from '../types';
import statisticServices from '../statisticServices';

export const getUsers = createAsyncThunk<
  IGetUsersPayload,
  IGetUsersArgs,
  ThunkAPIConfig
>('getUsers', async (args, { rejectWithValue }) => {
  try {
    const { data } = await statisticServices.getUsers({
      page: args.page || 1,
      limit: args.limit || 5,
      ...args
    });

    const { result, metaData } = data.data;
    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUsersBuilder = (
  builder: ActionReducerMapBuilder<IStatisticState>
) => {
  const { pending, fulfilled, rejected } = getUsers;
  builder
    .addCase(pending, draftState => {
      draftState.users.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.users = {
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination
      };
    })
    .addCase(rejected, draftState => {
      draftState.users.loading = false;
    });
};
