import { AccountStatus, IAccountState } from '../types';
import { ACTIVATE_ACCOUNT } from '../constants';
import accountServices from '../accountServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const activateAccount = createAsyncThunk<
  any,
  { accountId: number },
  ThunkAPIConfig
>(ACTIVATE_ACCOUNT, async (args, { rejectWithValue }) => {
  try {
    const { data } = await accountServices.activateAccount(args.accountId);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const activateAccountBuilder = (
  builder: ActionReducerMapBuilder<IAccountState>
) => {
  const { pending, fulfilled, rejected } = activateAccount;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loading = false;
      // const accountId = action.meta.arg.accountId;
      // draftState.accountData.map(item => {
      //   if (item.id === accountId) {
      //     return (item.status = AccountStatus.Active);
      //   }
      // });
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
