import React, { PropsWithChildren } from 'react';
import ModalTitleBase from 'react-bootstrap/ModalTitle';
import { ModalTitleProps } from './types';

const ModalTitle: React.FC<PropsWithChildren<ModalTitleProps>> = ({
  children
}) => {
  return <ModalTitleBase>{children}</ModalTitleBase>;
};

export default ModalTitle;
