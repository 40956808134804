import { IPaginationData } from 'app/global-types/globals';
import { IFile } from 'pages/OtherManagement/types';

export interface IGetPostArgs extends IGetDataArgs {
  content?: string;
  petType?: PetType;
}

export enum PetType {
  Alive = 'ALIVE',
  Dead = 'DEAD'
}

export enum PetDisease {
  General = 'GENERAL',
  Respiratory = 'RESPIRATORY',
  Tumor = 'TUMOR',
  Heart = 'HEART',
  Hormone = 'HORMONE',
  Liver = 'LIVER',
  Stomach = 'STOMACH',
  Ophthalmology_Dental = 'OPHTHALMOLOGY_DENTAL',
  Ear = 'EAR',
  Nervous = 'NERVOUS',
  Skin = 'SKIN',
  Urinary = 'URINARY',
  Etc = 'ETC'
}

export enum ExpertPageType {
  List = 'List',
  Detail = 'Detail',
  Edit = 'Edit'
}

export interface IPost {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  content: string;
  totalLikes: number;
  totalComments: number;
  totalShares: number;
  allowedComment: boolean;
  liked: boolean;
  postType: string;
  files: IFile[];
  pet: {
    id: number;
    name: string;
    type: PetType;
    diseases: PetDisease[];
    avatar: {
      id: number;
      url: string;
    };
  };
  user: {
    id: number;
    username: string;
    avatar: {
      id: number;
      url: string;
    };
  };
}

export interface IGetPostPayload {
  data: IPost[];
  pagination: IPaginationData;
}

export interface IPostState {
  loading: boolean;
  postData: IPost[];
  pagination: IPaginationData;
  filter: {
    content?: string;
    petType?: PetType;
  };
  activeTabId: string;
  selectedPost?: IPost;

  loadingExpert: boolean;
  postDataExpert: IPost[];
  paginationExpert: IPaginationData;
  filterExpert: {
    content?: string;
    petType?: PetType;
  };
  selectedPostExpert?: IPost;
  expertPageType?: ExpertPageType;
}
