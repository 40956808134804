import SimpleBar from 'simplebar-react';
import React, { PropsWithChildren } from 'react';
import Header from 'pages/_commons/Header';
import Sidebar from 'pages/_commons/Sidebar';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';

interface IMainLayoutProps {
  className?: string;
}

const MainLayout: React.FC<PropsWithChildren<IMainLayoutProps>> = ({
  className,
  children
}) => {
  return (
    <div className={classnames('h-100', className)}>
      {/* <Header /> */}
      <div className="d-flex h-100">
        <Sidebar />
        <div className="flex-1">
          <SimpleBar
            style={{
              background: '#F8F9F9'
            }}
          >
            {children}
            <Outlet />
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
