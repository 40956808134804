import React from 'react';
import { Toast as ToastBase, ToastBody, ToastContainer } from 'react-bootstrap';
import { InlineMessage } from 'app/components';
import { selectToastList } from './_redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IToast, toastActions } from './_redux';

const ToastControl: React.FC = () => {
  const dispatch = useDispatch();
  const data: IToast[] = useSelector(selectToastList);

  const handleClose = (id: string) => {
    dispatch(toastActions.closeToast(id));
  };

  return (
    <ToastContainer position="top-center" className="mt-16">
      {data.map(({ id, show, variant, message }) => (
        <ToastBase
          key={`toast-${id}`}
          delay={3000}
          show={show}
          autohide={true}
          onClose={() => handleClose(id)}
        >
          <ToastBody className="p-0">
            <InlineMessage variant={variant}>{message}</InlineMessage>
          </ToastBody>
        </ToastBase>
      ))}
    </ToastContainer>
  );
};

export default ToastControl;
