export enum FILTER_TYPE {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}

export enum FILTER_TOPIC {
    NEW_USER = "newUser",
    WITHDRAW = "withdraw",
    FRENQUENCY = "frequency"
}

export const filterTypes = [
    {
        id: FILTER_TYPE.DAILY,
        label: "일간",
    },
    {
        id: FILTER_TYPE.WEEKLY,
        label: "주간",
    },
    {
        id: FILTER_TYPE.MONTHLY,
        label: "월간",
    },
    {
        id: FILTER_TYPE.YEARLY,
        label: "연간",
    },
];

export const filterTopics = [
    {
        id: FILTER_TOPIC.NEW_USER,
        label: "신규 회원",
    },
    {
        id: FILTER_TOPIC.WITHDRAW,
        label: "기업구분",
    },
]
