import { IPaginationData } from 'app/global-types/globals';
import { ACCOUNT_STATUS_KEY } from './constants';

export interface IGetAccountArgs extends IGetDataArgs {
  status?: ACCOUNT_STATUS_KEY;
  name?: string;
}

export enum AccountType {
  Email = 'EMAIL',
  Google = 'GOOGLE',
  Kakao = 'KAKAO',
  Naver = 'NAVER',
  Apple = 'APPLE'
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Block = 'BLOCK',
  Removed = 'REMOVED',
  CancelMembership = 'CANCEL_MEMBERSHIP'
}

export interface IAccount {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: AccountStatus;
  userType: AccountType;
  email: string;
  username: string;
  name: string;
  phoneNumber: string;
}

export interface IAccountDetailed extends IAccount {
  totalReported: number;
  dob: string;
  avatar: {
    id: number;
    url: string;
  };
}

export interface IAccountState {
  loading: boolean;
  accountData: IAccount[];
  pagination: IPaginationData;
  filter: {
    name?: string;
    status?: ACCOUNT_STATUS_KEY;
  };
  activeTabId: string;
  selectedAccount?: IAccountDetailed;
}

export interface IGetAccountPayload {
  data: IAccount[];
  pagination: IPaginationData;
}
