import {
  IUserProductState,
  IGetUserProductArgs,
  IGetUserProductPayload
} from '../@type';
import { GET_REVIEWS } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'app/utils/constant';
import UserProductServices from '../service';

export const getUserProducts = createAsyncThunk<
  IGetUserProductPayload,
  IGetUserProductArgs,
  ThunkAPIConfig
>(GET_REVIEWS, async (args, { rejectWithValue }) => {
  try {
    const params = {
      ...args,
      page: args?.page || 1,
      limit: args?.limit || PAGE_SIZE,
      content: args.content ? args.content : undefined,
      category: args.type ? args.type : undefined
    };

    const { data } = await UserProductServices.getUserProductList(
      params as any
    );
    const { result, metaData } = data.data;

    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserProductBuilder = (
  builder: ActionReducerMapBuilder<IUserProductState>
) => {
  const { pending, fulfilled, rejected } = getUserProducts;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, pagination } = action.payload;
      draftState.loading = false;
      draftState.userProductData = data;
      draftState.pagination = pagination;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
