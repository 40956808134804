import { ISuperdatProductState, SuperdatProductType } from '../@type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSuperdatProducts,
  getSuperdatProductBuilder
} from './getSuperdatProduct';
import {
  deleteSuperdatProduct,
  deleteSuperdatProductBuilder
} from './deleteSuperdatProduct';
import {
  editSuperdatProduct,
  editSuperdatProductBuilder
} from './editSuperdatProduct';

import {
  createSuperdatProduct,
  createSuperdatProductBuilder
} from './createSuperdatProduct';

const initialState: ISuperdatProductState = {
  loading: false,
  superdatProductData: [],
  pagination: {},
  filter: {
    keyword: undefined,
    type: undefined
  },
  activeTabId: 'all'
};

const { actions, reducer } = createSlice({
  name: 'superdatProduct',
  initialState,
  reducers: {
    setFilterData: (
      draftState,
      action: PayloadAction<{ keyword?: string; type?: SuperdatProductType }>
    ) => {
      if (action.payload?.keyword) {
        draftState.filter.keyword = action.payload.keyword;
      } else {
        draftState.filter.keyword = undefined;
      }
      draftState.filter.type = action.payload.type;
    },
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;
      if (action.payload === 'all') {
        draftState.filter.type = undefined;
      } else if (action.payload === 'food') {
        draftState.filter.type = SuperdatProductType.FOOD;
      } else {
        draftState.filter.type = SuperdatProductType.MEDICINE;
      }
    }
  },
  extraReducers: builder => {
    getSuperdatProductBuilder(builder);
    deleteSuperdatProductBuilder(builder);
    createSuperdatProductBuilder(builder);
    editSuperdatProductBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getSuperdatProducts,
  deleteSuperdatProduct,
  createSuperdatProduct,
  editSuperdatProduct
};

export { combineActions as superdatProductActions, reducer };
