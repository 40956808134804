import { IPostState, IGetPostArgs, IGetPostPayload } from '../types';
import { GET_EXPERT_POSTS } from '../constants';
import postServices from '../postServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getExpertPosts = createAsyncThunk<
  IGetPostPayload,
  IGetPostArgs,
  ThunkAPIConfig
>(GET_EXPERT_POSTS, async (args, { rejectWithValue }) => {
  try {
    const params = {
      ...args,
      page: args?.page || 1,
      limit: args?.limit || 5,
      content: args.content ? args.content : undefined,
      petType: args.petType ? args.petType : undefined,
      postTypes: 'EXPERT'
    };

    const { data } = await postServices.getExpertPostList(params);
    const { result, metaData } = data.data;

    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExpertPostsBuilder = (
  builder: ActionReducerMapBuilder<IPostState>
) => {
  const { pending, fulfilled, rejected } = getExpertPosts;
  builder
    .addCase(pending, draftState => {
      draftState.loadingExpert = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, pagination } = action.payload;
      draftState.loadingExpert = false;
      draftState.postDataExpert = data;
      draftState.paginationExpert = pagination;
    })
    .addCase(rejected, draftState => {
      draftState.loadingExpert = false;
    });
};
