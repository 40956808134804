import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDeleteAnnouncementArgs } from '../types';
import otherManagementServices from '../otherManagementServices';
import { batch } from 'react-redux';
import { otherManagementActions } from '.';
import { toastActions } from 'pages/_commons/Toast/_redux';

export const deleteAnnouncement = createAsyncThunk<
  unknown,
  IDeleteAnnouncementArgs,
  ThunkAPIConfig
>('deleteAnnouncement', async (args, { dispatch, rejectWithValue }) => {
  try {
    await otherManagementServices.deleteAnnouncement(args);

    return batch(() => {
      dispatch(
        toastActions.addToast({
          show: true,
          message: '성공',
          type: 'success'
        })
      );
      dispatch(
        otherManagementActions.getAnnouncements({
          limit: 5
        })
      );
      dispatch(otherManagementActions.setAnnouncementAction('list'));
      dispatch(otherManagementActions.setSelectedAnnouncement());
    });
  } catch (error) {
    dispatch(
      toastActions.addToast({
        show: true,
        message: '오류',
        type: 'error'
      })
    );
    return rejectWithValue(error);
  }
});
