import { IMedicalState } from '../types';
import { DELETE_MEDICAL_REPLY } from '../constants';
import medicalServices from '../medicalServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { medicalActions } from '.';
import { PAGE_SIZE } from 'app/utils/constant';

export const deleteMedicalReply = createAsyncThunk<
  any,
  { id: number; replyId: number },
  ThunkAPIConfig
>(DELETE_MEDICAL_REPLY, async (args, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await medicalServices.deleteReply(args.id, args.replyId);
    dispatch(
      medicalActions.getMedicals({
        page: 1,
        limit: PAGE_SIZE
      })
    );
    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteMedicalReplyBuilder = (
  builder: ActionReducerMapBuilder<IMedicalState>
) => {
  const { pending, fulfilled, rejected } = deleteMedicalReply;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.backList = true;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
