import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IPaginationData } from 'app/global-types/globals';
import {
  IBill,
  IBillReduxState,
  ICreateBillBody,
  IEditBillBody,
  IGetBillNameParam,
  IGetBillNameResponse,
  IGetBillParam,
  IResponse
} from '../dataListManagement.types';
import { dataListAPI } from '../dataListManagement.service';

export const getBillNameThunk = createAsyncThunk<
  IResponse<{ result: IGetBillNameResponse[]; metaData: IPaginationData }>,
  IGetBillNameParam,
  ThunkAPIConfig
>('dataList/getBillName', async (args, { rejectWithValue }) => {
  try {
    const { data } = await dataListAPI.getBillName(args);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillNameBuilder = (
  builder: ActionReducerMapBuilder<IBillReduxState>
) => {
  const { pending, fulfilled, rejected } = getBillNameThunk;
  builder
    .addCase(pending, draftState => {
      draftState.listBillNameLoading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;

      draftState.listBillNameLoading = false;
      draftState.billNamePagination = data.metaData;
      draftState.listBillName = data.result;
    })
    .addCase(rejected, draftState => {
      draftState.listBillNameLoading = false;
      draftState.listBillName = [];
    });
};

export const getBillsThunk = createAsyncThunk<
  IResponse<{ result: IBill[]; metaData: IPaginationData }>,
  IGetBillParam,
  ThunkAPIConfig
>('dataList/getBills', async (args, { rejectWithValue }) => {
  try {
    const { data } = await dataListAPI.getBills(args);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillsBuilder = (
  builder: ActionReducerMapBuilder<IBillReduxState>
) => {
  const { pending, fulfilled, rejected } = getBillsThunk;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;

      draftState.loading = false;
      draftState.pagination = data.metaData;
      draftState.listBillData = data.result;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
      draftState.listBillData = [];
    });
};

export const createBillThunk = createAsyncThunk<
  unknown,
  {
    body: ICreateBillBody;
    callback?: () => void;
    handleError?: (errorCode: number) => void;
  },
  ThunkAPIConfig
>(
  'dataList/createBills',
  async ({ body, callback, handleError }, { rejectWithValue }) => {
    try {
      const { data } = await dataListAPI.createBill(body);
      callback?.();
      return data;
    } catch (error) {
      handleError?.(error?.response?.status || 400);
      return rejectWithValue(error);
    }
  }
);
export const createBillBuilder = (
  builder: ActionReducerMapBuilder<IBillReduxState>
) => {
  const { pending, fulfilled, rejected } = createBillThunk;
  builder
    .addCase(pending, draftState => {
      draftState.saving = true;
    })
    .addCase(fulfilled, draftState => {
      draftState.saving = false;
    })
    .addCase(rejected, draftState => {
      draftState.saving = false;
    });
};

export const editBillThunk = createAsyncThunk<
  unknown,
  {
    body: IEditBillBody;
    callback?: () => void;
    handleError?: ({
      errorCode,
      errorMessage
    }: {
      errorCode: number;
      errorMessage: string;
    }) => void;
  },
  ThunkAPIConfig
>(
  'dataList/editBills',
  async ({ body, callback, handleError }, { rejectWithValue }) => {
    try {
      const { data } = await dataListAPI.editBill(body);
      callback?.();
      return data;
    } catch (error) {
      handleError?.({
        errorCode: error?.response?.status || 400,
        errorMessage: error?.response?.data?.message?.text || ''
      });
      return rejectWithValue(error);
    }
  }
);
export const editBillBuilder = (
  builder: ActionReducerMapBuilder<IBillReduxState>
) => {
  const { pending, fulfilled, rejected } = editBillThunk;
  builder
    .addCase(pending, draftState => {
      draftState.saving = true;
    })
    .addCase(fulfilled, (draftState: any, action) => {
      const { body } = action.meta.arg;
      const newData = [...draftState.listBillData].map(item => {
        if (item?.medicineName === body?.medicineName) {
          return {
            ...item,
            category: body?.new_category,
            db_input: body?.new_db_input || '',
            subCategory1: body?.new_subCategory1,
            subCategory2: body?.new_subCategory2
          };
        }
        return item;
      });

      draftState.listBillData = newData;
      draftState.saving = false;
    })
    .addCase(rejected, draftState => {
      draftState.saving = false;
    });
};

export const deleteBillThunk = createAsyncThunk<
  unknown,
  {
    body: { ids: number[] };
    callback?: () => void;
    handleError?: (errorCode: number) => void;
  },
  ThunkAPIConfig
>(
  'dataList/deleteBills',
  async ({ body, callback, handleError }, { rejectWithValue }) => {
    try {
      const { data } = await dataListAPI.deleteMultipleBill(body);
      callback?.();
      return data;
    } catch (error) {
      handleError?.(error?.response?.status || 400);
      return rejectWithValue(error);
    }
  }
);
export const deleteBillBuilder = (
  builder: ActionReducerMapBuilder<IBillReduxState>
) => {
  const { pending, fulfilled, rejected } = deleteBillThunk;
  builder
    .addCase(pending, draftState => {
      draftState.deleting = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.deleting = false;
    })
    .addCase(rejected, draftState => {
      draftState.deleting = false;
    });
};
