import { createSelector } from '@reduxjs/toolkit';
import { IAuthState } from '../types';

const getAuthStates = (states: RootState) => states.auth;

export const selectSignInState = createSelector(
  getAuthStates,
  (data: IAuthState) => data.signIn
);

export const selectAuthLoading = createSelector(
  getAuthStates,
  (data: IAuthState) => data.loading
);

export const selectAuthUser = createSelector(
  getAuthStates,
  (data: IAuthState) => data.authUser
);
