import { apiService } from 'app/utils/api.service';

import {
  IAutoCompleteBillParam,
  IBill,
  IBillSearch,
  ICategory,
  ICreateBillBody,
  ICreateCategoryBody,
  IEditBillBody,
  IGetBillNameParam,
  IGetBillNameResponse,
  IGetBillParam,
  IGetCategoryParam,
  IResponse
} from './dataListManagement.types';
import { IPaginationData } from 'app/global-types/globals';

export const dataListAPI = {
  getCategory: (params: IGetCategoryParam) => {
    const apiUrl = window.appConfig.api.dataList.category;
    return apiService.get<IResponse<ICategory[]>>(apiUrl, {
      params
    });
  },
  createCategory: (body: ICreateCategoryBody) => {
    const apiUrl = window.appConfig.api.dataList.category;
    return apiService.post<IResponse<ICategory[]>>(apiUrl, body);
  },
  validateCategory: (params: { parentId?: number; name: string }) => {
    const apiUrl = `${window.appConfig.api.dataList.bill}/validateCategory`;
    return apiService.get<IResponse<ICategory[]>>(apiUrl, { params });
  },
  autoCompleteBill: (params: IAutoCompleteBillParam) => {
    const apiUrl = window.appConfig.api.dataList.search;
    return apiService.get<IResponse<IBillSearch[]>>(apiUrl, {
      params
    });
  },
  getBillName: (params: IGetBillNameParam) => {
    const apiUrl = `${window.appConfig.api.dataList.bill}/name`;
    return apiService.get<
      IResponse<{ result: IGetBillNameResponse[]; metaData: IPaginationData }>
    >(apiUrl, { params });
  },
  getBills: (params: IGetBillParam) => {
    const apiUrl = window.appConfig.api.dataList.bill;
    return apiService.get<
      IResponse<{ result: IBill[]; metaData: IPaginationData }>
    >(apiUrl, {
      params
    });
  },
  deleteBill: (billId: number) => {
    const apiUrl = `${window.appConfig.api.dataList.bill}/${billId}`;
    return apiService.delete(apiUrl);
  },
  deleteMultipleBill: (body: { ids: number[] }) => {
    const apiUrl = window.appConfig.api.dataList.bill;
    return apiService.delete(apiUrl, { data: body });
  },
  createBill: (body: ICreateBillBody) => {
    const apiUrl = window.appConfig.api.dataList.bill;
    return apiService.post(apiUrl, body);
  },
  editBill: (body: IEditBillBody) => {
    const apiUrl = `${window.appConfig.api.dataList.bill}/update`;
    return apiService.put(apiUrl, body);
  }
};
