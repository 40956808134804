import React, { ReactNode, useState } from 'react';

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import { IPost } from './types';
import PostGridActions from './actions';
import { ColumnType } from 'app/components';
import dateTime from 'date-and-time';
import ExpertPostGridActions from './expertActions';
import { TextBoxControl } from 'app/components/FormControls';
import TextAreaControl from 'app/components/FormControls/TextAreaControl';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const ListImg: React.FC<any> = (data: IPost) => {
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const images = data.files;

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Lightbox
        open={fileIndex >= 0}
        index={fileIndex}
        controller={{ closeOnBackdropClick: true }}
        close={() => setFileIndex(-1)}
        slides={images.map(it => ({ ...it, src: it.url, alt: 'image 1' }))}
        on={{ view: ({ index: currentIndex }) => setFileIndex(currentIndex) }}
        render={{
          buttonPrev:
            images.length <= 1 || fileIndex === 0 ? () => null : undefined,
          buttonNext:
            images.length <= 1 || fileIndex === images.length - 1
              ? () => null
              : undefined
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {images?.length > 0 &&
          images
            .slice(0, 3)
            .map((file, index) => (
              <Image
                key={file.id}
                src={file.url}
                alt=""
                height={88}
                width={88}
                onClick={() => setFileIndex(index)}
                style={{ objectFit: 'cover' }}
                className="br-radius-8 mr-8"
              />
            ))}
        {images?.length - 3 > 0 && (
          <span
            className="fw-400"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            +{data.files.length - 3}
          </span>
        )}
      </div>
    </div>
  );
};

export const POST_COLUMNS: ColumnType<IPost>[] = [
  {
    id: 'petType',
    Header: '구분',
    accessor: (data: IPost) => (
      <div className="text-align-center">
        <b className="fs-16">
          {data?.postType === 'NORMAL' ? '일반 게시글' : '별나라 편지'}
        </b>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 150,
    disableSortBy: true
  },
  {
    id: 'nickname',
    Header: '유저 닉네임',
    accessor: (data: IPost) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.user?.username || '-'}</span>
      </div>
    ),
    className: 'justify-content-center',
    width: 150,
    disableSortBy: true
  },
  {
    id: 'petName',
    Header: '펫 이름',
    accessor: (data: IPost) => (
      <div className="text-align-center">
        <span className="fw-400">{data?.pet?.name || '-'}</span>
      </div>
    ),
    className: 'justify-content-center',
    width: 150,
    disableSortBy: true
  },
  {
    id: 'detail',
    Header: '내용',
    accessor: (data: IPost) => (
      <div>
        <span className="text-content-elipsis">{data.content || '-'}</span>
      </div>
    ),
    className: 'justify-content-center',
    width: 320,
    disableSortBy: true
  },
  {
    id: 'image',
    Header: '이미지',
    accessor: (data: IPost) => <ListImg {...data} />,
    className: 'justify-content-center',
    width: 320,
    disableSortBy: true
  }
  //   {
  //     id: 'action',
  //     Header: '',
  //     accessor: (data: IPost) => <PostGridActions {...data} />,
  //     width: 190
  //   }
];

export const POST_EXPERT_COLUMNS: ColumnType<IPost>[] = [
  {
    id: 'createdAt',
    Header: '등록일',
    accessor: (data: IPost) => (
      <div className="text-align-center">
        <span className="fs-14 fw-500" style={{ color: '#464C52' }}>
          {data?.createdAt
            ? dateTime.format(new Date(data?.createdAt || ''), 'YYYY.MM.DD')
            : '-'}
        </span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 144 - 32,
    disableSortBy: true
  },
  {
    id: 'detail',
    Header: '내용',
    accessor: (data: IPost) => (
      <div>
        <span className="text-content-eclipse">{data.content || '-'}</span>
      </div>
    ),
    className: 'justify-content-center div-middle',
    width: 496 - 32,
    disableSortBy: true
  },
  {
    id: 'image',
    Header: '이미지',
    accessor: (data: IPost) => <ListImg {...data} />,
    className: 'justify-content-center',
    width: 320 - 32,
    disableSortBy: true
  },
  {
    id: 'action',
    Header: '관리',
    className: 'justify-content-center div-middle',
    accessor: (data: IPost) => <ExpertPostGridActions {...data} />,
    width: 144 - 32,
    disableSortBy: true
  }
];

export const GET_POSTS = 'post/getPosts';
export const DELETE_POST = 'post/deletePost';
export const GET_POST_DETAILED = 'post/getPostDetailed';

export const GET_EXPERT_POSTS = 'expertPost/getPosts';
export const DELETE_EXPERT_POST = 'expertPost/deletePost';
export const GET_EXPERT_POST_DETAILED = 'expertPost/getPostDetailed';

export const POST_USERS = 'USER_POST';
export const POST_EXPERTS = 'EXPERT_POST';
