import { IPostState } from '../types';
import { DELETE_EXPERT_POST } from '../constants';
import postServices from '../postServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const deleteExpertPost = createAsyncThunk<
  any,
  { postId: number },
  ThunkAPIConfig
>(DELETE_EXPERT_POST, async (args, { rejectWithValue }) => {
  try {
    const { data } = await postServices.deleteExpertPost(args.postId);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteExpertPostBuilder = (
  builder: ActionReducerMapBuilder<IPostState>
) => {
  const { pending, fulfilled, rejected } = deleteExpertPost;
  builder
    .addCase(pending, draftState => {
      draftState.loadingExpert = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loadingExpert = false;
      const postId = action.meta.arg.postId;
      const totalPage = draftState?.pagination?.totalRecords || 0;
      const filteredData = draftState.postDataExpert.filter(
        item => item.id !== postId
      );
      draftState.pagination.totalRecords = totalPage - 1;
      draftState.postDataExpert = filteredData;
    })
    .addCase(rejected, draftState => {
      draftState.loadingExpert = false;
    });
};
