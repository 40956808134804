import { AccountStatus, IAccountState } from '../types';
import { BLOCK_ACCOUNT } from '../constants';
import accountServices from '../accountServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const blockAccount = createAsyncThunk<
  any,
  { accountId: number },
  ThunkAPIConfig
>(BLOCK_ACCOUNT, async (args, { rejectWithValue }) => {
  try {
    const { data } = await accountServices.blockAccount(args.accountId);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const blockAccountBuilder = (
  builder: ActionReducerMapBuilder<IAccountState>
) => {
  const { pending, fulfilled, rejected } = blockAccount;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loading = false;
      const accountId = action.meta.arg.accountId;
      draftState.accountData.map(item => {
        if (item.id === accountId) {
          return (item.status = AccountStatus.Block);
        }
      });
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
