import React, { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import DatePickerBase, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { TextBox, Icon } from 'app/components';
import classes from 'app/utils/classes';
import { isEmpty, isString, isUndefined } from 'lodash';
import * as Popper from '@popperjs/core';
import Tooltip, { ITooltipProps } from '../Tooltip';
import parse from 'html-react-parser';
import ko from "date-fns/locale/ko";

export interface IDatePickerProps
  extends Omit<ReactDatePickerProps, 'value' | 'onChange'> {
  label?: string;
  value?: string | Date;
  textValue?: string;
  required?: boolean;
  popperPlacement?: Popper.Placement;
  error?: FormError;
  id?: string;
  name?: string;
  tooltipProps?: ITooltipProps;
  readOnlyText?: string;
  showWeekNumbers?: boolean;
  shouldCloseOnSelect?: boolean;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  isClearable?: boolean;
  customInput?: ReactNode;
  highlightDates?: Date[];
  onChange?: (e: Date) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  renderCustomHeader?: (props: ReactDatePickerCustomHeaderProps) => ReactNode;
}

const DatePicker: React.FC<PropsWithChildren<IDatePickerProps>> = props => {
  const {
    label,
    value: valueProp,
    textValue,
    required,
    minDate,
    maxDate,
    popperPlacement,
    error = {
      status: false,
      message: ''
    },
    name = '',
    id,
    tooltipProps,
    readOnly = false,
    readOnlyText,
    showMonthYearPicker,
    showYearPicker,
    showWeekNumbers,
    children,
    customInput,
    highlightDates,
    isClearable,
    onChange,
    onFocus,
    onBlur,
    renderCustomHeader
  } = props;

  registerLocale("kr", ko);
  const [value, setValue] = useState(
    isString(valueProp) ? new Date(valueProp) : valueProp || new Date()
  );
  const [focus, setFocus] = useState(false);

  const handleChange = useCallback(
    (e: Date) => {
      setValue(e);
      onChange && onChange(e);
    },
    [onChange]
  );

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
    e => {
      setFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    e => {
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  const isShowTooltip = useMemo(() => {
    if (!isUndefined(tooltipProps)) return undefined;
    if (!error.status || isEmpty(error.message) || !focus) return false;
    return true;
  }, [error.message, error.status, focus, tooltipProps]);

  const formatTooltipProps = useMemo<ITooltipProps>(
    () => ({
      ...tooltipProps,
      show: isShowTooltip,
      message: (
        <div>{parse((tooltipProps?.message || error.message) as string)}</div>
      ),
      variant: tooltipProps?.variant || 'danger',
      placement: tooltipProps?.placement || 'top-start'
    }),
    [error.message, isShowTooltip, tooltipProps]
  );

  return (
    <Tooltip {...formatTooltipProps} className="mb-n30">
      <DatePickerBase
        selected={value}
        value={textValue}
        startDate={value}
        id={id}
        name={name}
        customInput={customInput ? customInput :
          <TextBox
            label={label}
            textBoxRequired={required}
            readOnly
            readOnlyText={readOnlyText}
            suffix={<Icon name="calendar" />}
          />
        }
        locale="kr"
        calendarStartDay={1}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        showWeekNumbers={showWeekNumbers}
        highlightDates={highlightDates}
        calendarClassName={classes.datePicker.calendar}
        dayClassName={_ => classes.datePicker.day}
        monthClassName={_ => classes.datePicker.month}
        weekDayClassName={_ => classes.datePicker.weekday}
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={renderCustomHeader}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isClearable={isClearable}
        popperPlacement={popperPlacement}
        readOnly={readOnly}
        portalId="root"
      >
        {children}
      </DatePickerBase>
    </Tooltip>
  );
};

export default DatePicker;
