import { IMedical, IMedicalState, PetMedicalStatus } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMedicals, getMedicalsBuilder } from './getMedicals';
import {
  getMedicalDetailed,
  getMedicalDetailedBuilder
} from './getMedicalDetailed';
import { uploadFile, uploadFileBuilder } from './uploadFile';
import { replyMedical, replyMedicalBuilder } from './replyMedical';
import { editMedical, editMedicalBuilder } from './editMedical';
import { deleteMedicalReply, deleteMedicalReplyBuilder } from './deleteMedical';
import { getReply, getReplyBuilder } from './getReply';

const initialState: IMedicalState = {
  loading: false,
  medicalData: [],
  pagination: {},
  filter: {
    status: undefined
  },
  activeTabId: 'full',
  backList: false
};

const { actions, reducer } = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;
      if (action.payload === 'full') {
        draftState.filter.status = undefined;
      } else if (action.payload === 'waiting') {
        draftState.filter.status = PetMedicalStatus.Requested;
      } else {
        draftState.filter.status = PetMedicalStatus.Completed;
      }
    },
    setSelectedMedicalDetailed: (
      draftState,
      action: PayloadAction<IMedical | undefined>
    ) => {
      draftState.selectedMedical = action.payload;
    },
    setBackList: (draftState, action: PayloadAction<boolean>) => {
      draftState.backList = action.payload;
    }
  },
  extraReducers: builder => {
    getMedicalsBuilder(builder);
    getMedicalDetailedBuilder(builder);
    uploadFileBuilder(builder);
    replyMedicalBuilder(builder);
    editMedicalBuilder(builder);
    getReplyBuilder(builder);
    deleteMedicalReplyBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getMedicals,
  getMedicalDetailed,
  uploadFile,
  replyMedical,
  editMedical,
  deleteMedicalReply,
  getReply
};

export { combineActions as medicalActions, reducer };
