import { IReviewState } from '../types';
import reviewServices from '../reviewServices';
import { GET_REVIEW_DETAILED } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getReviewDetailed = createAsyncThunk<
  any,
  { reviewId: number },
  ThunkAPIConfig
>(GET_REVIEW_DETAILED, async (args, { rejectWithValue }) => {
  try {
    const { data } = await reviewServices.getReviewDetailed(args.reviewId);
    return {
      data: data.data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReviewDetailedBuilder = (
  builder: ActionReducerMapBuilder<IReviewState>
) => {
  const { pending, fulfilled, rejected } = getReviewDetailed;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
      draftState.selectedReview = data;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
