import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ExpertPageType } from '../types';
import { batch } from 'react-redux';
import { toastActions } from 'pages/_commons/Toast/_redux';
import { postActions } from '.';
import { updateFiles } from '../helpers';
import { isArray } from 'lodash';
import postServices from '../postServices';

export const savePost = createAsyncThunk<unknown, any, ThunkAPIConfig>(
  'savePost',
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const selectedPost = getState().post.selectedPostExpert;
      dispatch(
        toastActions.addToast({
          show: true,
          message: '파일 업로드 중...',
          type: 'warning'
        })
      );
      const files = await updateFiles(selectedPost?.files || [], args.files);
      if (!isArray(files)) throw new Error();
      const params: any = {
        data: {
          ...args,
          files
        }
      };
      await (args.id
        ? postServices.updateExpertPost(params)
        : postServices.createExpertPost(params));

      return batch(() => {
        dispatch(
          toastActions.addToast({
            show: true,
            message: '성공',
            type: 'success'
          })
        );
        dispatch(
          postActions.getExpertPosts({
            limit: 10
          })
        );
        dispatch(postActions.changeExpertPage(ExpertPageType.List));
      });
    } catch (error) {
      dispatch(
        toastActions.addToast({
          show: true,
          message: '오류',
          type: 'error'
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const savePostBuilder = (builder: ActionReducerMapBuilder<any>) => {
  const { pending, fulfilled, rejected } = savePost;
  builder
    .addCase(pending, draftState => {
      draftState.loadingExpert = true;
    })
    .addCase(fulfilled, draftState => {
      draftState.loadingExpert = false;
    })
    .addCase(rejected, draftState => {
      draftState.loadingExpert = false;
    });
};
