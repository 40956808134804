import { IGetAccountArgs } from './types';
import { apiService } from 'app/utils/api.service';

const accountServices = {
  getAccountList(params: IGetAccountArgs) {
    const apiUrl = window.appConfig.api.account.getAccountList;
    return apiService.get(apiUrl, {
      params
    });
  },
  blockAccount(accountId: number) {
    const apiUrl = `${window.appConfig.api.account.getAccountList}/${accountId}`;
    return apiService.delete(apiUrl);
  },
  getAccountDetailed(accountId: number) {
    const apiUrl = `${window.appConfig.api.account.getAccountList}/${accountId}`;
    return apiService.get(apiUrl);
  },
  activateAccount(accountId: number) {
    const apiUrl = `${window.appConfig.api.account.getAccountList}/${accountId}`;
    return apiService.put(apiUrl);
  }
};

export default accountServices;
