import { IPaginationData } from 'app/global-types/globals';

export enum UserProductTab {
  ALL = 'ALL',
  MEDICINE = 'MEDICINE',
  FOOD = 'FOOD',
  HANDMADE = 'HANDMADE'
}

export enum IGetUserProduct {}

export enum UserProductType {
  ALL = 'ALL',
  MEDICINE = 'MEDICINE',
  FOOD = 'FOOD',
  HANDMADE = 'HANDMADE'
}

export interface IGetReviewPayload {
  data: IUserProduct[];
  pagination: IPaginationData;
}

export interface IUserProduct {
  id: number;
  createdAt: string;
  category: string;
  kindOfProducts: any;
  kindOfProductsKr: any;
  productForAnimal: string;
  name: string;
  companyName: string;
  effectProduct: any;
  totalReviews: number;
  avgReview: number;
  suitableFor: any;
  size: any;
  allergens: any;
  productUserType: string;
  user: {
    id: number;
    username: string;
    avatar: any;
  };
  kindOfProductIcons: [];
}

export interface IGetUserProductPayload {
  data: IUserProduct[];
  pagination: IPaginationData;
}

export interface IUserProductState {
  loading: boolean;
  userProductData: IUserProduct[];
  pagination: IPaginationData;
  filter: {
    keyword?: string;
    type?: UserProductType;
  };
  activeTabId: string;
  selectedUserProduct?: IUserProduct;
}

export interface IGetUserProductArgs extends IGetDataArgs {
  content?: string;
  type?: UserProductType;
}
