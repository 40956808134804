import { apiService } from 'app/utils/api.service';
import {
  IApproveReportArgs,
  IBanner,
  IDeleteAnnouncementArgs,
  IDeleteReportArgs,
  IGetAnnouncementsArgs,
  IGetBannerArgs,
  IGetAnnouncementDetailArgs,
  IGetReportDetailArgs,
  IGetReportsArgs,
  ISaveAnnouncementArgs,
  IGetCategoriesArgs,
  IAddCategoryArgs,
  IUpdateCategoryArgs,
  IUploadFileArgs,
  IDeleteFileArgs,
  IAddBannerArgs,
  IUpdateBannerArgs,
  IUpdateAnnouncementArgs,
  ICategory
} from './types';

const otherManagementServices = {
  getBanners: (params: IGetBannerArgs) =>
    apiService.get(window.appConfig.api.other.getBanners, { params }),
  deleteBanner: (banner: IBanner) =>
    apiService.delete(window.appConfig.api.other.deleteBanner + banner.id),
  addBanner: (banner: IAddBannerArgs) =>
    apiService.post(window.appConfig.api.other.addBanner, banner.data),
  updateBanner: (banner: IUpdateBannerArgs) =>
    apiService.patch(
      window.appConfig.api.other.updateBanner + banner.data.id,
      banner.data
    ),

  getReports: (params: IGetReportsArgs) =>
    apiService.get(window.appConfig.api.other.getReports, { params }),
  getReportDetail: (params: IGetReportDetailArgs) =>
    apiService.get(window.appConfig.api.other.getReportDetail + params.data.id),
  approveReport: (params: IApproveReportArgs) =>
    apiService.put(
      window.appConfig.api.other.approveReport + params.data.id,
      params.data
    ),
  deleteReport: (params: IDeleteReportArgs) =>
    apiService.delete(window.appConfig.api.other.deleteReport + params.data.id),

  getAnnouncements: (params: IGetAnnouncementsArgs) =>
    apiService.get(window.appConfig.api.other.getAnnouncements, { params }),
  getAnnouncementDetail: (params: IGetAnnouncementDetailArgs) =>
    apiService.get(
      window.appConfig.api.other.getAnnouncementDetail + params.data.id
    ),
  addAnnouncement: (args: ISaveAnnouncementArgs) =>
    apiService.post(window.appConfig.api.other.addAnnouncement, args.data),
  updateAnnouncement: (args: IUpdateAnnouncementArgs) =>
    apiService.patch(
      window.appConfig.api.other.updateAnnouncement + args.data.id,
      args.data
    ),
  deleteAnnouncement: (args: IDeleteAnnouncementArgs) =>
    apiService.delete(
      window.appConfig.api.other.deleteAnnouncement + args.data.id
    ),

  getCategories: (params: IGetCategoriesArgs) =>
    apiService.get(window.appConfig.api.other.getCategories, { params }),
  addCategory: (params: any) =>
    apiService.post(window.appConfig.api.other.addCategory, params),
  updateCategory: (params: any) =>
    apiService.patch(
      window.appConfig.api.other.updateCategory + params.id,
      params
    ),
  deleteCategory: (params: ICategory) =>
    apiService.delete(window.appConfig.api.other.deleteCategory + params.id),

  uploadFiles: (params: IUploadFileArgs) =>
    apiService.post(window.appConfig.api.file.uploadFiles, params.formData),
  deleteFile: (params: IDeleteFileArgs) =>
    apiService.delete(window.appConfig.api.file.deleteFile + params.file.id)
};

export default otherManagementServices;
