import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Modal as ModalBase } from 'react-bootstrap';
import { ModalProps } from './types';
import classnames from 'classnames';
import classes from 'app/utils/classes';
import { useDispatch } from 'react-redux';
import { confirmModalActions } from 'pages/_commons/ConfirmModal/_redux';

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  show: showProp,
  classes: classesProp,
  loading,
  full,
  children,
  size,
  ...props
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(showProp);
  const [isLoading, setIsLoading] = useState(loading);
  const modalContentRef = useRef<any>(null);

  const {
    modal: modalClass,
    content: contentClass,
    backdrop: backdropClass
  } = classesProp || {};

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  // const handleKeyPress = (event: any) => {
  //   if (
  //     modalContentRef.current &&
  //     !modalContentRef.current.contains(event.target)
  //   ) {
  //     dispatch(confirmModalActions.onClose());
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener('click', handleKeyPress);
  //   return () => {
  //     document.removeEventListener('click', handleKeyPress);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ModalBase
      show={show}
      size={size}
      centered
      scrollable
      className={classnames(
        {
          [classes.modal.root]: true,
          [classes.modal.full]: full
        },
        modalClass
      )}
      backdropClassName={backdropClass}
      contentClassName={classnames({ loading: isLoading }, contentClass)}
      {...props}
    >
      <div ref={modalContentRef}>{children}</div>
    </ModalBase>
  );
};

export default Modal;
