import { IReviewState, ReviewType } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReviews, getReviewsBuilder } from './getReviews';
import { deleteReview, deleteReviewBuilder } from './deleteReview';
import {
  getReviewDetailed,
  getReviewDetailedBuilder
} from './getReviewDetailed';

const initialState: IReviewState = {
  loading: false,
  reviewData: [],
  pagination: {},
  filter: {
    keySearch: undefined,
    type: undefined
  },
  activeTabId: 'all'
};

const { actions, reducer } = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setFilterData: (
      draftState,
      action: PayloadAction<{ keySearch?: string; type?: ReviewType }>
    ) => {
      if (action.payload?.keySearch) {
        draftState.filter.keySearch = action.payload.keySearch;
      } else {
        draftState.filter.keySearch = undefined;
      }
      draftState.filter.type = action.payload.type;
    },
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;

      if (action.payload === 'all') {
        draftState.filter.type = undefined;
      } else if (action.payload === 'medicine') {
        draftState.filter.type = ReviewType.MEDICINE;
      } else {
        draftState.filter.type = ReviewType.FOOD;
      }
    }
  },
  extraReducers: builder => {
    getReviewsBuilder(builder);
    deleteReviewBuilder(builder);
    getReviewDetailedBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getReviews,
  deleteReview,
  getReviewDetailed
};

export { combineActions as reviewActions, reducer };
