import { IAccountState } from '../types';
import { createSelector } from '@reduxjs/toolkit';

const getAccountState = (states: RootState) => states.account;

export const selectAccountLoading = createSelector(
  getAccountState,
  (data: IAccountState) => data.loading
);

export const selectACcountData = createSelector(
  getAccountState,
  (data: IAccountState) => data.accountData
);

export const selectAccountPagination = createSelector(
  getAccountState,
  (data: IAccountState) => data.pagination
);

export const selectFilterData = createSelector(
  getAccountState,
  (data: IAccountState) => data.filter
);

export const selectActiveTabId = createSelector(
  getAccountState,
  (data: IAccountState) => data.activeTabId
);

export const selectAccountDetailed = createSelector(
  getAccountState,
  (data: IAccountState) => data.selectedAccount
);
