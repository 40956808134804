import { IPostState } from '../types';
import { DELETE_POST } from '../constants';
import postServices from '../postServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const deletePost = createAsyncThunk<
  any,
  { postId: number },
  ThunkAPIConfig
>(DELETE_POST, async (args, { rejectWithValue }) => {
  try {
    const { data } = await postServices.deletePost(args.postId);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deletePostBuilder = (
  builder: ActionReducerMapBuilder<IPostState>
) => {
  const { pending, fulfilled, rejected } = deletePost;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loading = false;
      const postId = action.meta.arg.postId;
      const filteredData = draftState.postData.filter(
        item => item.id !== postId
      );
      draftState.postData = filteredData;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
