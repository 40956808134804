// const ListImg: React.FC<any> = (data: ISuperdatProduct) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const images = data.files;

//   return (
//     <div
//       onClick={e => {
//         e.stopPropagation();
//         if (!isOpen) {
//           setIsOpen(true);
//         }
//       }}
//     >
//       {isOpen && (
//         <Lightbox
//           open={isOpen}
//           close={() => setIsOpen(false)}
//           slides={images.map(it => ({ ...it, src: it.url, alt: 'image 1' }))}
//         />
//       )}
//       <div
//         style={{
//           display: 'flex'
//         }}
//       >
//         {images?.length > 0 &&
//           images
//             .slice(0, 3)
//             .map(file => (
//               <Image
//                 key={file.id}
//                 src={file.url}
//                 alt=""
//                 height={88}
//                 width={88}
//                 style={{ objectFit: 'cover' }}
//                 className="br-radius-8 mr-2"
//               />
//             ))}
//         {images?.length - 3 > 0 && (
//           <span
//             className="fw-400"
//             style={{
//               display: 'flex',
//               alignItems: 'center'
//             }}
//           >
//             +{data.files.length - 3}
//           </span>
//         )}
//       </div>
//     </div>
//   );
// };

export const GET_DISEASES_TAG_MANAGEMENT = 'superdatProduct/getSuperdatProduct';
