import { IMedicalState } from '../types';
import { UPLOAD_FILE } from '../constants';
import medicalServices from '../medicalServices';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const uploadFile = createAsyncThunk<
  any,
  {
    data: FormData;
  },
  ThunkAPIConfig
>(UPLOAD_FILE, async (args, { rejectWithValue }) => {
  try {
    const { data } = await medicalServices.uploadImage(args.data);

    return {
      data
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileBuilder = (
  builder: ActionReducerMapBuilder<IMedicalState>
) => {
  const { pending, fulfilled, rejected } = uploadFile;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data } = action.payload;
      draftState.loading = false;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
