import { IDiseasesTagManagementState } from '../@type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDiseasesTag, getDiseasesTagBuilder } from './getDiseasesTagList';

const initialState: IDiseasesTagManagementState = {
  loading: false,
  diseasesTagData: []
};

const { actions, reducer } = createSlice({
  name: 'superdatProduct',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getDiseasesTagBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getDiseasesTag
};

export { combineActions as diseasesTagActions, reducer };
