import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import authServices from '../authServices';
import { authStorage } from '../helpers';
import { IAuthState, ISignInArgs, ISignInPayload } from '../types';

export const signIn = createAsyncThunk<
  ISignInPayload,
  { args: ISignInArgs; onError?: () => void },
  ThunkAPIConfig
>('auth/signIn', async ({ args, onError }, { rejectWithValue }) => {
  try {
    const response = await authServices.signIn(args);
    const { data } = response.data;

    authStorage.setToken(data.tokens.accessToken);
    authStorage.setAuthUser(data.user);

    window.location.href = '/';

    return {
      user: data.user
    };
  } catch (error) {
    onError?.();
    return rejectWithValue(error);
  }
});

export const signInBuilder = (builder: ActionReducerMapBuilder<IAuthState>) => {
  const { pending, fulfilled, rejected } = signIn;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      draftState.loading = false;
      draftState.authUser = action.payload.user;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
