import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle
} from 'app/components';
import { COLORS } from 'app/constants/common';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaQuestionCircle,
  FaTimesCircle
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { confirmModalActions } from './_redux';
import { selectConfirmModalData } from './_redux/selectors';

const ConfirmModal: React.FC = () => {
  const {
    show: showProp,
    title,
    message,
    confirmBtnText,
    cancelBtnText,
    onConfirm,
    onCancel,
    content,
    imgSrc,
    variant = 'warning',
    btnConfirmType,
    contentType = 'confirm',
    className,
    backdropClass
  } = useSelector(selectConfirmModalData);

  const isForm = contentType === 'form';

  const [show, setShow] = useState(showProp);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    onConfirm && onConfirm();
    dispatch(confirmModalActions.onClose());
  };

  const handleCancel = () => {
    dispatch(confirmModalActions.onClose());
    onCancel && onCancel();
  };

  const iconColor = useMemo(() => {
    switch (variant) {
      case 'danger':
        return COLORS.DANGER;
      case 'success':
        return COLORS.SUCCESS;
      case 'primary':
        return COLORS.PRIMARY;
      case 'warning':
        return COLORS.WARNING;
      default:
        return COLORS.DANGER;
    }
  }, [variant]);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const IconView = useMemo(() => {
    switch (variant) {
      case 'danger':
        return <FaTimesCircle color={iconColor} size={60} />;
      case 'success':
        return <FaCheckCircle color={iconColor} size={60} />;
      case 'primary':
        return <FaQuestionCircle color={iconColor} size={60} />;
      case 'warning':
        return <FaExclamationTriangle color={iconColor} size={60} />;
      default:
        return <FaExclamationTriangle color={iconColor} size={60} />;
    }
  }, [iconColor, variant]);

  return (
    <Modal
      show={show}
      classes={{
        content: `${
          isForm
            ? 'modal-content-wrapper modal-content-wrapper__form'
            : 'modal-content-wrapper'
        }`,
        modal: `modal-wrapper ${className}`,
        backdrop: backdropClass
      }}
      onHide={() => {
        handleCancel();
      }}
    >
      {isForm && (
        <ModalTitle>
          <span className="form__title">{title}</span>
        </ModalTitle>
      )}

      <ModalBody
        className={`${
          isForm ? 'text-center modal__form-custom' : 'text-center mt-24'
        } `}
      >
        {/* {imgSrc ? <img src={imgSrc} /> : IconView} */}
        {isForm ? (
          <div className="modal-content-form">
            <div className="modal-content-form__title">{content}</div>
          </div>
        ) : (
          <>
            <div
              className="modal-content-title"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '20px',
                color: '#1A1A1A',
                marginTop: 21
              }}
            >
              {title}
            </div>
            <div
              className="modal-content-message"
              style={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#1A1A1A',
                marginTop: 6
              }}
            >
              {message}
            </div>
          </>
        )}
      </ModalBody>
      {!isForm && (
        <ModalFooter>
          <div className="d-flex col-gap-12">
            <Button
              onClick={handleCancel}
              style={
                isForm
                  ? {
                      width: '152px',
                      height: '36px',
                      color: '#3D95FC',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      border: '1px solid #3D95FC'
                    }
                  : {
                      width: '92px',
                      height: '44px',
                      background: `#ffffff`,
                      border: '1.2px solid #E5E5E5',
                      borderRadius: '4px',
                      fontWeight: '700',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#1A1A1A'
                    }
              }
            >
              {cancelBtnText}
            </Button>
            <Button
              onClick={handleConfirm}
              variant={variant}
              style={
                isForm
                  ? {
                      width: '152px',
                      height: '36px',
                      color: '#FFFFFF',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      border: '1px solid #3D95FC'
                    }
                  : {
                      width: '92px',
                      height: '44px',
                      background: `${
                        btnConfirmType === 'primary' ? '#76B7FF' : '#333333'
                      }`,
                      borderRadius: '4px',
                      fontWeight: '700',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                      marginLeft: 18,
                      border: 'none'
                    }
              }
            >
              {confirmBtnText}
            </Button>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ConfirmModal;
