import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetAnnouncementDetailArgs, IGetAnnouncementDetailPayload, IOtherManagementState } from "../types";
import otherManagementServices from "../otherManagementServices";

export const getAnnouncementDetail = createAsyncThunk<
    IGetAnnouncementDetailPayload,
    IGetAnnouncementDetailArgs,
    ThunkAPIConfig
>('getAnnouncementDetail', async (args, { rejectWithValue }) => {
    try {
        const { data } = await otherManagementServices.getAnnouncementDetail(args);

        return {
            data: data.data,
        };
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getAnnouncementDetailBuilder = (builder: ActionReducerMapBuilder<IOtherManagementState>) => {
    const { pending, fulfilled, rejected } = getAnnouncementDetail;
    builder
        .addCase(pending, draftState => {
            draftState.announcement.loading = true;
        })
        .addCase(fulfilled, (draftState, action) => {
            draftState.announcement.loading = false;
            draftState.announcement.detail = action.payload.data;
        })
        .addCase(rejected, draftState => {
            draftState.announcement.loading = false;
        })
}
