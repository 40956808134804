import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ACCOUNT_STATUS_KEY } from '../constants';
import { IAccountState } from '../types';
import { activateAccount, activateAccountBuilder } from './activateAccount';
import { blockAccount, blockAccountBuilder } from './blockAccount';
import {
  getAccountDetailed,
  getAccountDetailedBuilder
} from './getAccountDetailed';
import { getAccounts, getAccountsBuilder } from './getAccounts';

const initialState: IAccountState = {
  loading: false,
  accountData: [],
  pagination: {},
  filter: {
    name: '',
    status: undefined
  },
  activeTabId: 'all'
};

const { actions, reducer } = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setFilterData: (
      draftState,
      action: PayloadAction<{ status?: ACCOUNT_STATUS_KEY; name: string }>
    ) => {
      draftState.filter.name =
        action.payload.name.length > 0 ? action.payload.name : undefined;
      draftState.filter.status = action.payload.status;
    },
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;
      if (action.payload === 'all') {
        draftState.filter.status = undefined;
      } else if (action.payload === 'active') {
        draftState.filter.status = ACCOUNT_STATUS_KEY.ACTIVE;
      } else {
        draftState.filter.status = ACCOUNT_STATUS_KEY.BLOCK;
      }
    }
  },
  extraReducers: builder => {
    getAccountsBuilder(builder);
    blockAccountBuilder(builder);
    activateAccountBuilder(builder);
    getAccountDetailedBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getAccounts,
  blockAccount,
  activateAccount,
  getAccountDetailed
};

export { combineActions as accountActions, reducer };
