import { createSlice } from '@reduxjs/toolkit';

import { IBillReduxState } from '../dataListManagement.types';
import {
  createBillBuilder,
  createBillThunk,
  deleteBillBuilder,
  deleteBillThunk,
  editBillBuilder,
  editBillThunk,
  getBillNameBuilder,
  getBillNameThunk,
  getBillsBuilder,
  getBillsThunk
} from './dataList.thunk';

const initialState: IBillReduxState = {
  loading: false,
  listBillData: [],
  deleting: false,
  saving: false,
  listBillNameLoading: false,
  listBillName: []
};

const { actions, reducer: billReducer } = createSlice({
  name: 'dataListBill',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getBillsBuilder(builder);
    createBillBuilder(builder);
    getBillNameBuilder(builder);
    editBillBuilder(builder);
    deleteBillBuilder(builder);
  }
});

const billActions = {
  ...actions,
  getBillsThunk,
  createBillThunk,
  getBillNameThunk,
  editBillThunk,
  deleteBillThunk
};

export { billActions, billReducer };
