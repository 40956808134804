import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';
import { Variant } from 'react-bootstrap/esm/types';

export interface IToastState {
  data: IToast[];
}

export interface IToast {
  id: string;
  show: boolean;
  message: string;
  variant: Variant;
  position: ToastPosition;
}

const initialStates: IToastState = {
  data: []
};

const { actions, reducer } = createSlice({
  name: 'toastControl',
  initialState: initialStates,
  reducers: {
    addToast: (
      draftState,
      action: PayloadAction<{
        show: boolean;
        type: 'success' | 'error' | 'warning';
        message: string;
      }>
    ) => {
      const { show, message, type } = action.payload;
      const variant = (() => {
        switch (type) {
          case 'success':
            return 'success';
          case 'error':
            return 'danger';
          case 'warning':
            return 'warning';
        }
      })();
      draftState.data = [
        { id: uniqueId(), show, message, variant, position: 'top-start' },
        ...draftState.data
      ];
    },
    closeToast: (draftState, action: PayloadAction<string>) => {
      draftState.data = draftState.data.filter(
        ({ id }) => id !== action.payload
      );
    }
  }
});

export { actions as toastActions, reducer };
