import React from 'react';

import { postActions } from './_redux';
import { useDispatch, useSelector } from 'react-redux';
import { confirmModalActions } from 'pages/_commons/ConfirmModal/_redux';

import { IPost } from './types';
import Button from 'app/components/Button';
import { FaPenAlt, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { selectExpertFilterData } from './_redux/selectors';

const ExpertPostGridActions: React.FC<IPost> = post => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterData = useSelector(selectExpertFilterData);

  const handleDeletePost = (e: any) => {
    e.stopPropagation();
    dispatch(
      confirmModalActions.onConfirm({
        title: `게시글을 삭제하시겠어요?`,
        message:
          '삭제한 게시글은 복구할 수 없으며,\n게시글을 작성한 유저에게\n삭제 알림이 전송됩니다.',
        cancelBtnText: '취소',
        confirmBtnText: '삭제',
        variant: 'warning',
        onConfirm: () => {
          dispatch(postActions.deleteExpertPost({ postId: post.id }));
          // setTimeout(() => {
          //   dispatch(
          //     postActions.getExpertPosts({
          //       ...filterData,
          //       page: 1
          //     })
          //   );
          // }, 1000);
        }
      })
    );
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Button
        onClick={e => handleDeletePost(e)}
        style={{
          width: 58,
          height: 34,
          marginBottom: 8,
          background: '#F8F9F9',
          border: '1px solid #E3E5E8',
          borderRadius: '4px',
          fontSize: 14,
          color: '#5e656e',
          fontWeight: 500
        }}
      >
        삭제
      </Button>
      <Button
        onClick={e => {
          e.stopPropagation();
          dispatch(postActions.setExpertDetail(post));
        }}
        style={{
          width: 58,
          height: 34,
          background: '#F8F9F9',
          border: '1px solid #E3E5E8',
          borderRadius: '4px',
          fontSize: 14,
          color: '#5e656e',
          fontWeight: 500
        }}
      >
        수정
      </Button>
    </div>
  );
};

export default ExpertPostGridActions;
