import { sessionStorage } from 'app/helpers/sessionStorage';

export const authStorage = {
  setToken: (token: string) => sessionStorage.set('AUTH_TOKEN', token),
  getToken: () => sessionStorage.get('AUTH_TOKEN'),
  setAuthUser: (user: MagicKeyValue) =>
    sessionStorage.set('AUTH_USER', JSON.stringify(user)),
  getAuthUser: () => {
    try {
      const authUser = sessionStorage.get('AUTH_USER');
      if (!authUser) throw new Error();
      return JSON.parse(authUser);
    } catch (error) {
      return undefined;
    }
  },
  clear: () => {
    sessionStorage.remove('AUTH_TOKEN');
    sessionStorage.remove('AUTH_USER');
    sessionStorage.remove('AUTH_REFRESH_TOKEN');
  },
  getRefreshToken: () => {
    try {
      return sessionStorage.get('AUTH_REFRESH_TOKEN')!;
    } catch (error) {
      return undefined;
    }
  },
};
