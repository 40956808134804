import LazyLoadingFallback from 'app/components/LazyLoadingFallback';
import { authStorage } from 'pages/Auth/helpers';
import { authActions } from 'pages/Auth/_redux';
import { selectAuthUser } from 'pages/Auth/_redux/selectors';
// import { getAuthUser, isAdmin } from 'pages/Auth/helpers';
import Error404 from 'pages/_commons/404';
import ConfirmModal from 'pages/_commons/ConfirmModal';
import ToastControl from 'pages/_commons/Toast';
import React, { Fragment, Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AUTH_NAVIGATION, IRoute, MAIN_NAVIGATION } from 'routes/constants';
import MainLayout from 'routes/MainLayout';

const AppContainer: React.FC = () => {
  const dispatch = useDispatch();
  const userAuthStorage = authStorage.getAuthUser();
  const userAuthStore = useSelector(selectAuthUser);

  useEffect(() => {
    if (!userAuthStore && userAuthStorage) {
      dispatch(authActions.setAuthUser(userAuthStorage));
    }
  }, [dispatch, userAuthStorage, userAuthStore]);

  const RouteView = useCallback(
    (routeData: IRoute[], isChild = false) =>
      routeData.map(({ key, Component, path, routes, index }) => (
        <Route
          key={key}
          element={
            <Suspense fallback={<LazyLoadingFallback />}>
              <Component />
            </Suspense>
          }
          path={path}
        >
          {routes && RouteView(routes, true)}
        </Route>
      )),
    []
  );

  return (
    <Fragment>
      <main>
        <ToastControl />
        <ConfirmModal />
        <div className="h-100">
          <Routes>
            {userAuthStorage && (
              <Route element={<MainLayout />}>
                {RouteView(MAIN_NAVIGATION)}
              </Route>
            )}
            <Route
              path="/"
              element={
                <Navigate
                  to={userAuthStorage ? 'accounts' : 'sign-in'}
                  replace
                />
              }
            />
            {RouteView(AUTH_NAVIGATION)}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </main>
    </Fragment>
  );
};

export default AppContainer;
