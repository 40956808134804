import { IPaginationData } from 'app/global-types/globals';

export interface IGetReviewArgs extends IGetDataArgs {
  content?: string;
  type?: ReviewType;
}

export enum ReviewType {
  ALL = 'ALL',
  MEDICINE = 'MEDICINE',
  FOOD = 'FOOD'
}

export enum PetDisease {
  General = 'GENERAL',
  Respiratory = 'RESPIRATORY',
  Tumor = 'TUMOR',
  Heart = 'HEART',
  Hormone = 'HORMONE',
  Liver = 'LIVER',
  Stomach = 'STOMACH',
  Ophthalmology_Dental = 'OPHTHALMOLOGY_DENTAL',
  Ear = 'EAR',
  Nervous = 'NERVOUS',
  Skin = 'SKIN',
  Urinary = 'URINARY',
  Etc = 'ETC'
}

export interface IReview {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  content: string;
  point: number;
  files: any[];
  product: {
    id: number;
    avgReview: number;
    category: string;
    companyName: string;
    createdAt: string;
    kindOfProductIcons: any[];
    kindOfProducts: any;
    kindOfProductsKr: any;
    name: string;
    totalReviews: number;
  };
  user: {
    id: number;
    username: string;
    avatar: {
      id: number;
      url: string;
    };
  };
}

export interface IGetReviewPayload {
  data: IReview[];
  pagination: IPaginationData;
}

export interface IReviewState {
  loading: boolean;
  reviewData: IReview[];
  pagination: IPaginationData;
  filter: {
    keySearch?: string;
    type?: ReviewType;
  };
  activeTabId: string;
  selectedReview?: IReview;
}
