import {
  ISuperdatProductState,
  IGetSuperdatProductArgs,
  IGetSuperdatProductPayload
} from '../@type';
import { GET_SUPERDAT_PRODUCT } from '../constants';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'app/utils/constant';
import superdatProductServices from '../service';

export const getSuperdatProducts = createAsyncThunk<
  IGetSuperdatProductPayload,
  IGetSuperdatProductArgs,
  ThunkAPIConfig
>(GET_SUPERDAT_PRODUCT, async (args, { rejectWithValue }) => {
  try {
    const params = {
      ...args,
      page: args?.page || 1,
      limit: args?.limit || PAGE_SIZE,
      category: args.type ? args.type : undefined
    };

    const { data } = await superdatProductServices.getSuperdatProductList(
      params as any
    );
    const { result, metaData } = data.data;

    return {
      data: result,
      pagination: metaData
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSuperdatProductBuilder = (
  builder: ActionReducerMapBuilder<ISuperdatProductState>
) => {
  const { pending, fulfilled, rejected } = getSuperdatProducts;
  builder
    .addCase(pending, draftState => {
      draftState.loading = true;
    })
    .addCase(fulfilled, (draftState, action) => {
      const { data, pagination } = action.payload;
      draftState.loading = false;
      draftState.superdatProductData = data;
      draftState.pagination = pagination;
    })
    .addCase(rejected, draftState => {
      draftState.loading = false;
    });
};
