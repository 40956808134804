import { ExpertPageType, IPostState, PetType } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPosts, getPostsBuilder } from './getPosts';
import { deletePost, deletePostBuilder } from './deletePost';
import { getPostDetailed, getPostDetailedBuilder } from './getPostDetailed';
import { getExpertPosts, getExpertPostsBuilder } from './getExpertPosts';
import { deleteExpertPost, deleteExpertPostBuilder } from './deleteExpertPost';
import { savePost, savePostBuilder } from './savePost';
import {
  getExpertPostDetailed,
  getExpertPostDetailedBuilder
} from './getExpertPostDetailed';

const initialState: IPostState = {
  loading: false,
  postData: [],
  pagination: {},
  filter: {
    content: undefined,
    petType: undefined
  },
  activeTabId: 'all',
  loadingExpert: false,
  postDataExpert: [],
  paginationExpert: {},
  filterExpert: {
    content: undefined,
    petType: undefined
  },
  expertPageType: ExpertPageType.List,
  selectedPostExpert: undefined
};

const { actions, reducer } = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setFilterData: (
      draftState,
      action: PayloadAction<{ content?: string; type?: PetType }>
    ) => {
      if (action.payload?.content) {
        draftState.filter.content = action.payload.content;
      } else {
        draftState.filter.content = undefined;
      }
      draftState.filter.petType = action.payload.type;
    },
    setFilterExpertData: (
      draftState,
      action: PayloadAction<{ content?: string; type?: PetType }>
    ) => {
      if (action.payload?.content) {
        draftState.filterExpert.content = action.payload.content;
      } else {
        draftState.filterExpert.content = undefined;
      }
      draftState.filterExpert.petType = action.payload.type;
    },
    activeTab: (draftState, action: PayloadAction<string>) => {
      draftState.activeTabId = action.payload;
      if (action.payload === 'all') {
        draftState.filter.petType = undefined;
      } else if (action.payload === 'active') {
        draftState.filter.petType = PetType.Alive;
      } else {
        draftState.filter.petType = PetType.Dead;
      }
    },
    clearAllFilter: draftState => {
      draftState.filter.content = '';
      draftState.filterExpert.content = '';
    },
    clearExpertPostData: draftState => {
      draftState.selectedPostExpert = undefined;
    },
    setExpertDetail: (draftState, action) => {
      draftState.selectedPostExpert = action.payload;
      draftState.expertPageType = ExpertPageType.Edit;
    },
    changeExpertPage: (draftState, action: PayloadAction<string>) => {
      if (draftState.selectedPostExpert?.id) {
        draftState.selectedPostExpert.content = '';
        draftState.selectedPostExpert.files = [];
      }

      if (action.payload === ExpertPageType.List) {
        draftState.expertPageType = ExpertPageType.List;
      } else if (action.payload === ExpertPageType.Detail) {
        draftState.expertPageType = ExpertPageType.Detail;
      } else {
        draftState.expertPageType = ExpertPageType.Edit;
      }
    }
  },
  extraReducers: builder => {
    getPostsBuilder(builder);
    deletePostBuilder(builder);
    getPostDetailedBuilder(builder);
    getExpertPostsBuilder(builder);
    deleteExpertPostBuilder(builder);
    getExpertPostDetailedBuilder(builder);
    savePostBuilder(builder);
  }
});

const combineActions = {
  ...actions,
  getPosts,
  deletePost,
  getPostDetailed,
  getExpertPosts,
  deleteExpertPost,
  getExpertPostDetailed,
  savePost
};

export { combineActions as postActions, reducer };
